import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

// Images
import Group_74103 from "../../Images/Group_74103.svg";
import Path_115542 from "../../Images/Path_115542.svg";
import Group_74104 from "../../Images/Group_74104.svg";
import Group_54508 from "../../Images/Group_54508.svg";

// Components
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import FooterMob from "../Layout/FooterMob";

// Icons
import { FaTelegramPlane } from "react-icons/fa";
import { useState } from "react";
import { IoMdAlert } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)

    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);

    const contactSubmit = (e) => {
        e.preventDefault();
        if (username !== "" && password !== "") {
            if (username !== "info@omsaigroup.com") {
                setError("Please enter valid Username.");
                return;
            } else if (password !== "omsaigroup_techigent") {
                setError("Please enter valid Password.");
                return;
            } else {
                localStorage.setItem('admin', 'yes')
                navigate("/dashboard");
            }
        } else {
            setError("Please enter your username and password.");
            return;
        }
    };


    return (
        <>
            <MainHeader
                img="c_img_career sm-carrer-img"
                name="Login"
                title="Be the part of an excellent organisation"
            />

            <div className="contact " data-aos="fade-up">
                <div className="tech_contact sm-none">
                    <img src={Group_74103} alt="tech" width="239px" height="239px" />
                </div>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-2 col-md-2"></div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div
                                style={{ color: "#000000", fontSize: "40px" }}
                                className="sm-30 mt-4"
                            >
                                <b className="sm-25">Login</b>
                            </div>
                            <div
                                className="mt-5 d-flex align-items-center"
                                style={{ gap: "25px" }}
                            >
                                <img src={Group_74104} alt="error" />
                                <img src={Path_115542} alt="error" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            {error && <div style={{ color: 'red' }}><IoMdAlert color="red" size='26px' className="mr-2" /> {error}</div>}
                            <form onSubmit={contactSubmit} style={{ width: '320px' }} >
                                <div className="row">
                                    <div className="col-12 col-lg-12 mt-4">
                                        <div className="form-group">
                                            <input
                                                className="form-control join_form"
                                                data-aos="fade-right"
                                                type="text"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                name="full_name"
                                                placeholder="Username"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-12 mt-4">
                                        <div className="form-group">
                                            <input
                                                className="form-control join_form"
                                                data-aos="fade-left"
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                name="from_subject"
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-center"
                                    style={{ padding: "22px 0px 50px 0px" }}
                                >
                                    <button className="btn btn-contact" data-aos="flip-right" type="submit">
                                        <div className="d-flex justify-content-center aling-items-center" >
                                            <div>Send</div>
                                            <div className="ml-2">
                                                <FaTelegramPlane style={{ fontSize: "17px" }} />
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="tech_circle">
                    <img
                        src={Group_54508}
                        alt="tech"
                        width="381px"
                        height="381px"
                        className="sm-none"
                    />
                </div>
            </div>
            <div className="footer_up">
                <div className="sm-none">
                    <Footer />
                </div>
                <div className="lg-none">
                    <FooterMob />
                </div>
            </div>
        </>
    );
};

export default Login;
