import React, { useState } from "react";
import "aos/dist/aos.css";
import Marquee from "react-fast-marquee";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Components

// Images
import Component231_1 from "../../Images/AlliaNces/1.jpg";
import Component232 from "../../Images/AlliaNces/2.jpg";
import Component233 from "../../Images/AlliaNces/3.jpg";
import Component234 from "../../Images/AlliaNces/4.jpg";
import Component235 from "../../Images/AlliaNces/5.jpg";
import Component236 from "../../Images/AlliaNces/6.jpg";
import Component237 from "../../Images/AlliaNces/7.jpg";
import Component238 from "../../Images/AlliaNces/8.jpg";
import Component239 from "../../Images/AlliaNces/9.jpg";
import Component240 from "../../Images/AlliaNces/10.jpg";
import Component241 from "../../Images/AlliaNces/11.jpg";
import Component242 from "../../Images/AlliaNces/12.jpg";
import Component243 from "../../Images/AlliaNces/13.jpg";
import Component244 from "../../Images/AlliaNces/14.jpg";
import Component245 from "../../Images/AlliaNces/15.jpg";
import Component246 from "../../Images/AlliaNces/16.jpg";
import Component247 from "../../Images/AlliaNces/17.jpg";
import Component248 from "../../Images/AlliaNces/18.jpg";
import Component249 from "../../Images/AlliaNces/19.jpg";
import Component250 from "../../Images/AlliaNces/20.jpg";
import Component251 from "../../Images/AlliaNces/21.jpg";
import Component252 from "../../Images/AlliaNces/22.jpg";
import Component253 from "../../Images/AlliaNces/23.jpg";
import Component254 from "../../Images/AlliaNces/24.jpg";
import Component255 from "../../Images/AlliaNces/25.jpg";
import Component256 from "../../Images/AlliaNces/26.jpg";
import Component257 from "../../Images/AlliaNces/27.jpg";
import Component258 from "../../Images/AlliaNces/28.jpg";
import Component259 from "../../Images/AlliaNces/29.jpg";
import Component260 from "../../Images/AlliaNces/30.jpg";
import Component261 from "../../Images/AlliaNces/31.jpg";
import Component262 from "../../Images/AlliaNces/32.jpg";
import Component263 from "../../Images/AlliaNces/33.jpg";
import Component264 from "../../Images/AlliaNces/34.jpg";
import Component265 from "../../Images/AlliaNces/35.jpg";
import Component266 from "../../Images/AlliaNces/36.jpg";
import Component267 from "../../Images/AlliaNces/37.jpg";
import Component268 from "../../Images/AlliaNces/38.jpg";
import Component269 from "../../Images/AlliaNces/39.jpg";
import Component270 from "../../Images/AlliaNces/40.jpg";

import Cyber from '../../Images/solutionsImg/Cybersecurity.jpg'
import inno from '../../Images/solutionsImg/innovation-technology-business-finance-background.jpg'
import Intelligent from '../../Images/solutionsImg/Intelligent.jpg'
import infrastructure from '../../Images/solutionsImg/it-infrastructure-operations-management.jpg'
import Mobility from '../../Images/solutionsImg/Mobility.jpg'
import Softwares from '../../Images/solutionsImg/Softwares.jpg'

import Customer1 from "../../Images/Clients/1.jpg";
import Customer3 from "../../Images/Clients/3.jpg";
import Customer4 from "../../Images/Clients/4.jpg";
import Customer5 from "../../Images/Clients/5.jpg";
import Customer6 from "../../Images/Clients/6.jpg";
import Customer7 from "../../Images/Clients/7.jpg";
import Customer8 from "../../Images/Clients/8.jpg";
import Customer9 from "../../Images/Clients/9.jpg";
import Customer10 from "../../Images/Clients/10.jpg";
import Customer11 from "../../Images/Clients/11.jpg";
import Customer12 from "../../Images/Clients/12.jpg";
import Customer13 from "../../Images/Clients/13.jpg";
import Customer14 from "../../Images/Clients/14.jpg";
import Customer15 from "../../Images/Clients/15.jpg";
import Customer16 from "../../Images/Clients/16.jpg";
import Customer17 from "../../Images/Clients/17.jpg";
import Customer18 from "../../Images/Clients/18.jpg";
import Customer19 from "../../Images/Clients/19.jpg";
import Customer20 from "../../Images/Clients/20.jpg";
import Customer21 from "../../Images/Clients/21.jpg";
import Customer22 from "../../Images/Clients/22.jpg";
import Customer23 from "../../Images/Clients/23.jpg";
import Customer24 from "../../Images/Clients/24.jpg";
import Customer25 from "../../Images/Clients/25.jpg";
import Customer26 from "../../Images/Clients/26.jpg";
import Customer27 from "../../Images/Clients/27.jpg";
import Customer28 from "../../Images/Clients/28.jpg";
import Customer29 from "../../Images/Clients/29.jpg";
import Customer30 from "../../Images/Clients/30.jpg";
import Customer31 from "../../Images/Clients/31.jpg";
import Customer32 from "../../Images/Clients/32.jpg";
import Customer33 from "../../Images/Clients/33.jpg";
import Customer34 from "../../Images/Clients/34.jpg";
import Customer35 from "../../Images/Clients/35.jpg";
import Customer36 from "../../Images/Clients/36.jpg";
import Customer37 from "../../Images/Clients/37.jpg";
import Customer38 from "../../Images/Clients/38.jpg";
import Customer39 from "../../Images/Clients/39.jpg";
import Customer40 from "../../Images/Clients/40.jpg";
import Customer41 from "../../Images/Clients/41.jpg";
import Customer42 from "../../Images/Clients/42.jpg";
import Customer43 from "../../Images/Clients/43.jpg";
import Customer44 from "../../Images/Clients/44.jpg";
import Customer45 from "../../Images/Clients/45.jpg";
import Customer46 from "../../Images/Clients/46.jpg";
import Customer47 from "../../Images/Clients/47.jpg";
import Customer48 from "../../Images/Clients/48.jpg";
import Customer49 from "../../Images/Clients/49.jpg";
import Customer50 from "../../Images/Clients/50.jpg";
import Component_226_5 from "../../Images/Component_226_5.svg";
import { useNavigate } from "react-router-dom";

// Icons


const AwardSolution = () => {
    const navigate = useNavigate()
    const [alliance, setAlliance] = useState('')
    const [step, setStep] = useState(0)
    const [step1, setStep1] = useState(0)



    const allAlliance = [
        {
            name: "Cyber security",
            image: Component231_1,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Component259,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component232,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component233,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component234,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component235,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component236,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component237,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component238,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component239,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component240,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component244,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component245,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component256,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component257,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component246,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component247,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Component248,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Component249,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Component250,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Component251,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component252,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component253,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component241,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Component242,
            row: 2,
        },
        {
            name: "Software",
            image: Component267,
            row: 1,
        },
        {
            name: "Mobility",
            image: Component268,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component243,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component254,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component255,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component258,
            row: 2,
        },
        {
            name: "Software",
            image: Component265,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Component260,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Component261,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Component262,
            row: 1,
        },
        {
            name: "Software",
            image: Component263,
            row: 1,
        },
        {
            name: "Software",
            image: Component264,
            row: 1,
        },
        {
            name: "Software",
            image: Component266,
            row: 1,
        },
        {
            name: "Mobility",
            image: Component267,
            row: 1,
        },
        {
            name: "Mobility",
            image: Component269,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Component270,
            row: 1,
        },
    ]

    const Customers = [
        {
            name: "Cyber security",
            image: Customer1,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer3,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer4,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer5,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer6,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer7,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer8,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer9,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer10,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer11,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer12,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer13,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer14,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer15,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer16,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer17,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Customer18,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Customer19,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Customer20,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Customer21,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer22,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer23,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer24,
            row: 2,
        },
        {
            name: "Cyber security",
            image: Customer25,
            row: 2,
        },
        {
            name: "Software",
            image: Customer26,
            row: 1,
        },
        {
            name: "Mobility",
            image: Customer27,
            row: 1,
        },
        {
            name: "Cyber security",
            image: Customer28,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer29,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer30,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer31,
            row: 2,
        },
        {
            name: "Software",
            image: Customer32,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Customer33,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer34,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Customer35,
            row: 1,
        },
        {
            name: "Software",
            image: Customer36,
            row: 1,
        },
        {
            name: "Software",
            image: Customer37,
            row: 1,
        },
        {
            name: "Software",
            image: Customer38,
            row: 1,
        },
        {
            name: "Mobility",
            image: Customer39,
            row: 1,
        },
        {
            name: "Mobility",
            image: Customer40,
            row: 1,
        },
        {
            name: "Mobility",
            image: Customer41,
            row: 1,
        },
        {
            name: "Mobility",
            image: Customer42,
            row: 1,
        },
        {
            name: "Mobility",
            image: Customer43,
            row: 1,
        },
        {
            name: "Infrastructure",
            image: Customer44,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer45,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer46,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer47,
            row: 2,
        },
        {
            name: "Infrastructure",
            image: Customer48,
            row: 2,
        },

        {
            name: "Infrastructure",
            image: Customer49,
            row: 2,
        },

        {
            name: "Infrastructure",
            image: Customer50,
            row: 2,
        },
    ]

    const [updateList, setUpdate] = useState(allAlliance)

    const handlesetAlliance = (nameF) => {
        if (nameF !== '') {
            setAlliance(nameF)
            setUpdate(allAlliance.filter((list) => list.name === nameF))
            setStep(0)
        } else {
            setAlliance(nameF)
            setUpdate(allAlliance)
            setStep(0)
        }
    }

    return (

        <div className="sm-pt1" data-aos="fade-up">
            <div className="productandservice sm-pt1">
                <div className="container " style={{ position: "relative" }}>
                    <img
                        src={Component_226_5}
                        alt="logo"
                        className="car_effect_03 sm-none"
                    />

                    <div style={{ color: "#111111", fontSize: "30px" }}>
                        <b className="sm-25">Our Solutions</b>
                    </div>
                    <div style={{ color: "#555554", fontSize: "18px" }}>
                        Being Future Ready
                    </div>
                    <div style={{ paddingTop: "30px" }}>
                        <div className="row" data-aos="fade-left">
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="pas_box"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate('/cyberSecurity/network-perimeter')}>
                                    <img
                                        src={Cyber}
                                        alt="error"
                                        width="100%"
                                        height="227px"
                                        style={{ borderRadius: "8px 8px 0px 0px" }}
                                    />
                                    <div
                                        className="d-flex  justify-content-center align-items-center"
                                        style={{ height: "100px", cursor: "pointer" }}
                                    >
                                        <div
                                            style={{ fontSize: "18px", fontWeight: 'bold' }}
                                            className="sm-20"
                                        >
                                            Cyber Security
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="pas_box"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate('/infrastructure/smart-data')}>
                                    <img
                                        src={infrastructure}
                                        alt="error"
                                        width="100%"
                                        height="227px"
                                        style={{ borderRadius: "8px 8px 0px 0px" }}
                                    />
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "100px", cursor: "pointer" }}
                                    >
                                        <div
                                            style={{ fontSize: "18px", fontWeight: 'bold' }}
                                            className="sm-20"
                                        >
                                            Infrastructure Modernization
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="pas_box"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate('/product&service/software-solutions')}>
                                    <img
                                        src={Softwares}
                                        alt="error"
                                        width="100%"
                                        height="227px"
                                        style={{ borderRadius: "8px 8px 0px 0px" }}
                                    />
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "100px", cursor: "pointer" }}
                                    >
                                        <div
                                            style={{ fontSize: "18px", fontWeight: 'bold' }}
                                            className="sm-20"
                                        >
                                            Software Solutions
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: "30px" }} data-aos="fade-right">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="pas_box"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate('/product&service/endpoints')}>
                                    <img
                                        src={Intelligent}
                                        alt="error"
                                        width="100%"
                                        height="227px"
                                        style={{ borderRadius: "8px 8px 0px 0px" }}
                                    />
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "100px", cursor: "pointer" }}
                                    >
                                        <div
                                            style={{ fontSize: "18px", fontWeight: 'bold' }}
                                            className="sm-20"
                                        >
                                            Intelligent Devices
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="pas_box"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate('/product&service/mobility-solutions')}>
                                    <img
                                        src={Mobility}
                                        alt="error"
                                        width="100%"
                                        height="227px"
                                        style={{ borderRadius: "8px 8px 0px 0px" }}
                                    />
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "100px", cursor: "pointer" }}

                                    >
                                        <div
                                            style={{
                                                fontSize: "18px",
                                                width: "228px",
                                                textAlign: "center", fontWeight: 'bold'
                                            }}
                                            className="sm-20"
                                        >
                                            Mobility
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="pas_box"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate('/product&service/services-solutions')}>
                                    <img
                                        src={inno}
                                        alt="error"
                                        width="100%"
                                        height="227px"
                                        style={{ borderRadius: "8px 8px 0px 0px" }}
                                    />
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "100px", cursor: "pointer" }}
                                        onClick={() => navigate('/product&service/services-solutions')}
                                    >
                                        <div
                                            style={{ fontSize: "18px", fontWeight: 'bold' }}
                                            className="sm-20"
                                        >
                                            Services
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pas_box_2 " data-aos="fade-up">
                <div className="pas_box_1 sm-pt2">
                    <div className="container" style={{ position: "relative" }}>
                        <img
                            src={Component_226_5}
                            alt="logo"
                            className="car_effect_03 sm-none"
                        />
                        <div className="pas_box_text">
                            <div style={{ fontSize: "30px" }}>
                                <b className="sm-25">Our Alliances</b>
                            </div>
                            <div style={{ fontSize: "18px" }}>
                                More then 150 Brands & Counting
                            </div>
                        </div>

                        <div
                            style={{
                                fontSize: "18px",
                                marginTop: "52px",
                                borderBottom: "1px solid #D1D3D4",
                                cursor: "pointer",
                            }}
                            className="d-flex align-items-end justify-content-between sm-scroll sm-gap-20"
                        >
                            <div
                                className={
                                    alliance === ''
                                        ? "alliance_effect_active sm-14"
                                        : "alliance_effect_inactive sm-14"
                                }
                                onClick={() => handlesetAlliance('')}
                                style={{ width: "120px" }}
                            >
                                All Partners
                            </div>
                            <div
                                className={
                                    alliance === 'Cyber security'
                                        ? "alliance_effect_active sm-14"
                                        : "alliance_effect_inactive sm-14"
                                }
                                onClick={() => handlesetAlliance('Cyber security')}
                            >
                                Cybersecurity
                            </div>
                            <div
                                className={
                                    alliance === 'Infrastructure'
                                        ? "alliance_effect_active sm-14"
                                        : "alliance_effect_inactive sm-14"
                                }
                                onClick={() => handlesetAlliance('Infrastructure')}
                            >
                                Infrastructure
                            </div>
                            <div
                                className={
                                    alliance === 'Software'
                                        ? "alliance_effect_active sm-14"
                                        : "alliance_effect_inactive sm-14"
                                }
                                onClick={() => handlesetAlliance('Software')}
                            >
                                Software
                            </div>
                            <div
                                className={
                                    alliance === 'Mobility'
                                        ? "alliance_effect_active sm-14"
                                        : "alliance_effect_inactive sm-14"
                                }
                                onClick={() => handlesetAlliance('Mobility')}
                            >
                                Mobility
                            </div>
                        </div>
                        <div className="sm-none">
                            <div
                                style={{ paddingTop: "50px" }}
                                className="d-flex justify-content-between "
                            >
                                {updateList.filter((list) => list.row === 1).slice(step, step + 6).map((item, index) => (
                                    <div className="comp_logo d-flex align-items-center justify-content-center " key={index}>
                                        <img
                                            src={item.image}
                                            alt="error"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                ))}
                            </div>
                            <div
                                style={{ paddingTop: "30px" }}
                                className="d-flex justify-content-between"
                            >
                                {updateList.filter((list) => list.row === 2).slice(step, step + 6).map((item, index) => (
                                    <div className="comp_logo d-flex align-items-center justify-content-center " key={index}>
                                        <img
                                            src={item.image}
                                            alt="error"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="lg-none">
                            <Marquee
                                behavior="scroll"
                                direction="left"
                                speed="100"
                                gradientColor="none"
                            >
                                <div
                                    style={{ paddingTop: "50px" }}
                                    className="d-flex justify-content-between sm-gap-20"
                                >
                                    {updateList.map((item, index) => (
                                        <div className="comp_logo d-flex align-items-center justify-content-center " key={index}>
                                            <img
                                                src={item.image}
                                                alt="error"
                                                width="100%"
                                                height="100%"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Marquee>
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center mt-5 sm-none"
                            style={{ gap: "11px" }}
                        >
                            <div className={step === 0 ? "dot-active" : "dot-inactive"} onClick={() => setStep(0)}></div>
                            {(alliance === '' || alliance === 'Cyber security') &&
                                <div className={step === 7 ? "dot-active" : "dot-inactive"} onClick={() => setStep(7)}></div>
                            }
                            {alliance === '' &&
                                <div className={step === 13 ? "dot-active" : "dot-inactive"} onClick={() => setStep(13)}></div>
                            }
                            {alliance === '' &&
                                <div className={step === 19 ? "dot-active" : "dot-inactive"} onClick={() => setStep(19)}></div>
                            }

                        </div>
                    </div>
                </div>

                <div className="pas_box_1 sm-pt1" data-aos="fade-up">
                    <div className="container" style={{ position: "relative" }}>
                        <img
                            src={Component_226_5}
                            alt="logo"
                            className="car_effect_03 sm-none"
                        />

                        <div className="pas_box_text sm-pt1">
                            <div style={{ fontSize: "30px" }}>
                                <b className="sm-25">Our Privileged Customers</b>
                            </div>
                            <div style={{ fontSize: "18px" }}>
                                Serving Domestic & Global Clients
                            </div>
                        </div>

                        <div className="sm-none">
                            <div
                                style={{ paddingTop: "50px" }}
                                className="d-flex justify-content-between"
                            >

                                {Customers.filter((list) => list.row === 1).slice(step1, step1 + 6).map((item, index) => (

                                    <div className="comp_logo d-flex align-items-center justify-content-center" key={index}>
                                        <img
                                            src={item.image}
                                            alt="error"
                                            width="160px"
                                            height="160px"
                                        />
                                    </div>
                                ))}
                            </div>
                            <div
                                style={{ paddingTop: "30px" }}
                                className="d-flex justify-content-between"
                            >

                                {Customers.filter((list) => list.row === 2).slice(step1, step1 + 6).map((item, index) => (

                                    <div className="comp_logo d-flex align-items-center justify-content-center" key={index}>
                                        <img
                                            src={item.image}
                                            alt="error"
                                            width="160px"
                                            height="160px"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="lg-none">
                            <Marquee
                                behavior="scroll"
                                direction="left"
                                speed="100"
                                gradientColor="none"
                            >
                                <div
                                    style={{ paddingTop: "50px" }}
                                    className="d-flex justify-content-between sm-gap-20"
                                >
                                    {Customers.map((item, index) => (
                                        <div className="comp_logo d-flex align-items-center justify-content-center" key={index}>
                                            <img
                                                src={item.image}
                                                alt="error"
                                                width="160px"
                                                height="160px"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Marquee>
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center mt-5 sm-none"
                            style={{ gap: "11px" }}
                        >
                            <div className={step1 === 0 ? "dot-active" : "dot-inactive"} onClick={() => setStep1(0)}></div>
                            <div className={step1 === 7 ? "dot-active" : "dot-inactive"} onClick={() => setStep1(7)}></div>
                            <div className={step1 === 13 ? "dot-active" : "dot-inactive"} onClick={() => setStep1(13)}></div>
                            <div className={step1 === 19 ? "dot-active" : "dot-inactive"} onClick={() => setStep1(19)}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AwardSolution