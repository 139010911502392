import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

// Images

// Components
import MainHeader from "../Layout/MainHeader";
import Footer from "../Layout/Footer";
import FooterMob from "../Layout/FooterMob";
import caseStudies from "../../Images/solutionsImg/caseStudies.png";

// Dummy Images

const CaseStudieDetails = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);
    return (
        <>
            <MainHeader
                img="c_img_case_details sm-carrer-img"
                name=""
                title=""
            />
            <div className="c_img_cases sm-none">
                <img src={caseStudies} alt="error" style={{
                    boxShadow: '0px 10px 30px #1587c317', borderRadius: '10px'
                }} width="239px" height="159px" className='sm-carrer-img' />
            </div>

            <div className="c_img_cases lg-none">
                <img src={caseStudies} alt="error" style={{
                    boxShadow: '0px 10px 30px #1587c317', borderRadius: '10px'
                }} height="120px" width='150px' />
            </div>
            <div style={{
                marginTop: '0px',
                marginBottom: '50px'
            }}>
                <div className="container"
                    style={{ paddingBottom: '20px', position: 'relative' }}
                >

                    <div className="caseStudy_1" data-aos="fade-up">
                        <div
                            className="container"
                            style={{ position: "relative" }}
                        >
                            <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                                <div className="col-lg-12 col-md-12 col-12 ">
                                    <div style={{ fontSize: "32px", paddingRight: '10px' }}><b className='sm-25'>Airspan</b></div>
                                    <p className="mt-3" style={{ fontSize: "16px", width: "100%" }}>
                                        Airspan Networks is an American telecommunications company headquartered in Boca Raton, Florida. The company develops Radio Access Network technology including the Sprint 'Magic Box' and cells (both small and macro) for the Rakuten virtualized network.[1]
                                        <br /><br />
                                        Airspan was originally a product division of DSC Communications, a manufacturer of telephone switching equipment. Original products included a CDMA-based radio platform used for the fixed wireless market. In 1998, the company separated from DSC Communications and announced Eric Stonestrom as CEO and President.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="container"
                            style={{ paddingBottom: "10px", position: "relative" }}
                        >
                            <div className="row sm-pt4" style={{ padding: "10px 0px 50px 0px" }} data-aos="fade-right">
                                <div className="col-lg-12 col-md-12 col-12 ">
                                    <div style={{ fontSize: "32px", paddingRight: '10px' }}><b className='sm-25'>Background</b></div>
                                    <p className="mt-3" style={{ fontSize: "16px", width: "100%" }}>
                                        <ul>
                                            <li>Create the Smart City as a blueprint for the future of smart cities in India</li>
                                            <li>Showcase how IoE is transforming the way cities, companies and citizens create and deliver innovation, energy, healthcare, education and transportation</li>
                                            <li>Provide a collaborative office experience to employees</li>
                                            <li>Develop a scalable, sustainable solution that could be replicated at other Cisco campuses globally Network Solution </li>
                                            <li>Cisco Smart+Connected solution which includes next-generation network technologies for energy management, collaborative workspaces, indoor navigation applications and sign-based information</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="container"
                            style={{ paddingBottom: "10px", position: "relative" }}
                        >
                            <div className="row sm-pt4" style={{ padding: "10px 0px 50px 0px" }} data-aos="fade-right">
                                <div className="col-lg-12 col-md-12 col-12 ">
                                    <div style={{ fontSize: "32px", paddingRight: '10px' }}><b className='sm-25'>The Products Techigent Provided</b></div>
                                    <p className="mt-3" style={{ fontSize: "16px", width: "100%" }}>
                                        <b>IoT Gateways</b><br />
                                        Cisco 800 Industrial Integrated Services Routers are compact and designed for harsh environments. Use them to oversee a variety of Internet of Things (IoT) gateway tasks, such as:
                                        <br /><br />
                                        <ul>
                                            <li>Distribution automation</li>
                                            <li>Pipeline and roadway infrastructure monitoring </li>
                                            <li>Highly secure connectivity for remote assets</li>
                                            <li>Management of fleet vehicles</li>
                                            <li>Mass transit applications</li>
                                            <li>Connecting all areas of business</li>
                                        </ul>
                                        <b>Ethernet Switches</b><br />
                                        A wide variety of Cisco Switches were offered with below features
                                        <br /><br />
                                        <ul>
                                            <li>Easy monitor and diagnostic by alarm relay and LED indicator</li>
                                            <li>Easy deployment and very fast boot up time</li>
                                            <li>Easy management by web based device manager</li>
                                            <li>Power over Ethernet (PoE) or PoE Plus support for in-line power up to 8 PoE devices</li>
                                        </ul>
                                        <b>Cisco Umbrella</b><br />
                                        Cisco Umbrella is a cloud security platform that provides the first line of defence against threats on the internet wherever users go
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="container"
                            style={{ paddingBottom: "10px", position: "relative" }}
                        >
                            <div className="row sm-pt4" style={{ padding: "10px 0px 50px 0px" }} data-aos="fade-right">
                                <div className="col-lg-12 col-md-12 col-12 ">
                                    <div style={{ fontSize: "32px", paddingRight: '10px' }}><b className='sm-25'> Business Results</b></div>
                                    <p className="mt-3" style={{ fontSize: "16px", width: "100%" }}>
                                        <ul>
                                            <li>A fully networked city- The Smart City features networked smart parking, connected cafes, recreation and relaxation rooms, a rooftop solar power system and indoor “neighbourhoods” designed for employees to quickly meet (in person or virtually), collaborate, innovate and deliver wherever they are located.</li>
                                            <li>A work environment that helps reduce carbon footprint, lower costs and provide more sustainable operations. The Smart City, uses intelligent networks as the foundation for managed city and business services and incorporates all manner of mobility, security, cloud computing, virtualization, collaboration, video and other evolving technologies.</li>
                                            <li>The Smart City allows employees, customers and partners to experience what’s possible when you realize the true potential of the Internet of Everything and connect people, processes, data and things.</li>
                                            <li>Cisco’s S+CC solution enables the utilization of various value-added services over the network, thereby minimizing human intervention for routine facilities requirements </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="container"
                            style={{ paddingBottom: "10px", position: "relative" }}
                        >
                            <div className="row sm-pt4" style={{ padding: "10px 0px 50px 0px" }} data-aos="fade-right">
                                <div className="col-lg-12 col-md-12 col-12 ">
                                    <div style={{ fontSize: "32px", paddingRight: '10px' }}><b className='sm-25'> Network Solution</b></div>
                                    <p className="mt-3" style={{ fontSize: "16px", width: "100%" }}>
                                        Techigent provided Cisco products to showcase how the Cisco Smart+Connected solution works which includes next-generation network technologies for energy management, collaborative workspaces, indoor navigation applications and sign-based information.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        <b>The IoE technologies showcased at the Smart City include the following:</b>
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "16px", width: "100%" }}>
                                        <b> • Smart Parking:</b> The Smart parking system showcases how IoE technology can be used in a future city where sensors in the parking lot, help to monitor the number of vehicles parked and indicate empty space available for parking. Using a mobile phone app, users can check the space available. Users may also pre-book parking slots in malls, cinema multiplexes, etc. Cities can also maximize monetization of parking lots by setting differential pricing based on demand. IoE-based parking solutions that tie in road users, parking data and policies or city laws are estimated to help reduce 30% of the traffic rush caused by motorists who are looking for parking and help drive revenues for city councils and private parking providers – a clear win-win!
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        <b>• Smart Buildings:</b> Smart buildings are built and operated, monitored and controlled for reduced energy and water consumption, reduced carbon emissions, lower costs and a better work environment. Such Smart building solutions can help cities, companies and citizens reduce the estimated 40% of energy consumed by buildings worldwide.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        <b>• Energy Management Solution: </b> Cisco’s Energy Management solution helps energy management by monitoring energy spend by each and every device, controlling energy consumption by using a policy-based approach and managing entire energy management workflow by providing insights on Energy Consumed, Saved and Spent. The solution covers all devices and systems across IT, Office Space, Data Center and Building Systems. This helps to determine energy consumption/savings on weekdays and measure the same against consumption/saving on weekends. Designed to monitor energy usage of every device connected to the network, this solution is estimated to help cut energy costs by 35%. This concept resonates well with Smart City whereby the solution can help to remotely turn on or off the street lights, parking, homes, offices and workspaces.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        <b>• Remote Expert Solution:</b> The Remote Expert solution is designed so that local, regional and central government administrations can offer high quality, efficient and cost-effective citizen services through an immersive collaboration experience and from a more convenient location, close to the residence or workplace. Within a Smart City, citizens can conveniently have face-to-face, cost-effective access to government representatives and services from remote locations. The virtual pool of experts sitting in a centralized environment helps to maximize the effectiveness and reach of their knowledge base. Quick and easy access to expert government services increases citizen engagement and satisfaction. Cost reduction through real estate consolidation results from centralization of expert resources; reduces carbon footprint and enables better traffic flow management as citizens no longer need to travel long distances to government centers.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        <b> • Connected Learning:</b> Education is fundamentally a collaborative, interactive endeavour. Cities, companies and educational institutions can use connected learning solutions to improve access to education beyond local resources by using network-based collaboration technologies to improve student outcomes, increase efficiency, enhance safety and security, and expand research capabilities. This solution is a showcase of the latest advancements in remote education and remote healthcare.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        Colleges connected via this solution are able to conduct classes virtually for providing remote education to students across the globe. While it helps teachers connect with more students simultaneously for knowledge transfer, students have easy, convenient access to experts from across the globe. The same concept when applied to healthcare enables remote consultation whereby doctors connect with patients remotely and conduct detailed clinical examination and review all investigation using the internet.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        <b>• Smart Work Spaces:</b> The Smart City has been designed taking into consideration the future of work – a future that is not just about connected devices, but also about when and where people work, and how companies can foster productivity and creativity in the workplace. Smart Meeting Spaces is a solution to enable employees to collaborate together anywhere, anytime instantly through collaboration and mobility tools. The Smart Personalized Spaces solution makes work spaces available for employees when and where they need it, on demand. According to a global industry study, 66 percent of employees’ desire work flexibility while 60 percent of employees believe they do not need to be in the office to be productive.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        Smart Work Space solutions accommodate and attract a new generation of workers, facilitate creativity and collaboration, and enhance overall effectiveness and efficiency. Tools like Cisco Maps (navigation applications and sign-based information) make it easier for employees to locate meeting rooms and book them through their device of choice (Extension Mobility) because the user can get information pertaining to occupancy status of each room, by floor, time, as well as the devices available in the room like a/c, internet, signboards, etc.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        Going a step further, cubicles inside the new buildings of the Smart City will carry QR codes which the user can scan to log in and personalize the workspace. Security is also built-in to such borderless offices where privilege-based network access is provided to users based on their role and access specific content. Such tools can be used by the hotel industry for check-in and customize guest rooms. Likewise hospitals can use the tools to customize rooms for their patients.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        High definition video solutions are enabling Cisco employees to collaborate through any device of choice from any location at any time. Tools like Cisco Jabber, Cisco AnyConnect provide employees the luxury of being able to connect to the corporate network irrespective of physical location even while on travel through the internet. Within a Smart City such collaboration solutions - integrated video sharing platforms - allow various government and private agencies to easily and quickly communicate, collaborate and share resources to enhance overall city-wide operations for public safety and transportation besides enabling situational awareness through real-time collaboration for improved decision making. This in turn helps improve services levels, resource utilization, and response times.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        • In addition to the above, other solutions like CiscoServiceGrid which is designed to automate and manage all Tech Operations in a Smart City, leverages heavy on technology solutions by integrating multi-vendor management with support process flow and SLA.
                                    </p>
                                    <p className="mt-3" style={{ fontSize: "18px", width: "100%" }}>
                                        According to Wim Elfrink, Cisco Executive Vice President for Industry Solutions and Chief Globalisation Officer, “Cities are growing at the rate of 10,000 people per hour even as we accelerate toward a world with more connected devices. For every two people connected around the globe, there are five others who soon will be. And for every device connected to the internet, ten more will join it in the near future. Through the Internet of Everything, we can help countries, cities and communities embrace sustainable urban development and enable economic, social and environmental sustainability.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_up">
                <div className="sm-none">
                    <Footer />
                </div>
                <div className="lg-none">
                    <FooterMob />
                </div>
            </div>
        </>
    );
};

export default CaseStudieDetails;
