import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

// Images
import Component_226_5 from "../../Images/Component_226_5.svg";
import Group_74103 from "../../Images/Group_74103.svg";
import Group_54508 from "../../Images/Group_54508.svg";

// Components
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import FooterMob from "../Layout/FooterMob";
import emailjs from '@emailjs/browser';

// Icons
import { FaTelegramPlane } from "react-icons/fa";
import { BiCurrentLocation, BiLinkAlt } from "react-icons/bi";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const Careers = () => {
  const navigate = useNavigate()
  const [imageUpload, setImageUpload] = useState(null);
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [message, setMessage] = useState()
  const [department, setDepartment] = useState()
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 90 });
    getData()
  }, []);

  const contactCreate = (e, url) => {
    e.preventDefault()
    axios.post('https://api-techigent.vercel.app/newCandidate/create', {
      Name: name,
      email: email,
      mobile: phone,
      Department: department,
      message: message,
      Status: '1',
      resume: url,
      companyStatus: 2
    })
      .then((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      });
    e.target.reset();
  }
  const contactSubmit = (e, url) => {
    e.preventDefault();
    contactCreate(url)
    emailjs.sendForm('service_zv75tqd', 'template_b54a2ke', e.target, '3Tidp3rBHayyWJqSU')
      .then((result) => {
        console.log(result.text);
        alert('Successfully sended')
      }, (error) => {
        console.log(error.text);
      });
  };

  const [data, setData] = useState([])
  const getData = () => {
    axios.get('https://api-techigent.vercel.app/vacancy/all')
      .then((response) => {
        setData(response.data.filter((list) => list.companyStatus === 2))
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    <>
      <MainHeader
        img="c_img_career sm-carrer-img"
        name="Careers"
        title="Be the part of an excellent organisation"
      />

      <div className="c_content_01">
        <div className="container">
          <div
            className="d-flex align-items-center sm-carrer-01 sm-16"
            style={{ color: "#FFFFFF", height: "224px", fontSize: "25px" }}
          >
            If you're interested, start by applying here and attaching your
            resume, we always have an empty chair for good talent, we don't ask
            for degrees but we do need skills and dedication.
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "90px" }} className="sm-pd-top">
        <div className="container" style={{ position: "relative" }}>
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />
          <div
            style={{

              position: "relative",
              paddingTop: "10px",
            }}
            className="thirty"
            data-aos="fade-up"
          >
            <div>
              <b className="sm-25">Departments</b>
            </div>
          </div>

          <div
            className="row sm-pt2"
            style={{ paddingTop: "50px" }}
            data-aos="fade-up"
          >
            <div className="col-lg-4 col-md-4 col-12">
              <div className="career_box_active d-flex justify-content-center align-items-center flex-column">
                <div style={{ fontSize: "18px", color: "#000000" }}>
                  Marketing & Sales
                </div>
                <div style={{ fontSize: "18px", color: "#C51213" }}>
                  {data.filter((list) => list.Department === "Marketing & Sales").length} Openings
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="career_box_active d-flex justify-content-center align-items-center flex-column">
                <div style={{ fontSize: "18px", color: "#000000" }}>
                  Accounting & Finance
                </div>
                <div style={{ fontSize: "18px", color: "#C51213" }}>
                  {data.filter((list) => list.Department === "Accounting & Finance").length} Openings
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="career_box_active d-flex justify-content-center align-items-center flex-column">
                <div style={{ fontSize: "18px", color: "#000000" }}>
                  Operations & Distribution
                </div>
                <div style={{ fontSize: "18px", color: "#C51213" }}>
                  {data.filter((list) => list.Department === "Operations & Distribution").length} Openings
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row"
            style={{ padding: "50px 0px" }}
            data-aos="fade-up"
          >
            <div className="col-lg-4 col-md-4 col-12">
              <div className="career_box_active d-flex justify-content-center align-items-center flex-column">
                <div style={{ fontSize: "18px", color: "#000000" }}>
                  Technology Solutions
                </div>
                <div style={{ fontSize: "18px", color: "#C51213" }}>
                  {data.filter((list) => list.Department === "Technology Solutions").length} Openings
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="career_box_active d-flex justify-content-center align-items-center flex-column">
                <div style={{ fontSize: "18px", color: "#000000" }}>
                  HR & Admin
                </div>
                <div style={{ fontSize: "18px", color: "#C51213" }}>
                  {data.filter((list) => list.Department === "HR & Admin").length} Openings
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="career_box_active d-flex justify-content-center align-items-center flex-column">
                <div style={{ fontSize: "18px", color: "#000000" }}>
                  Service & Support
                </div>
                <div style={{ fontSize: "18px", color: "#C51213" }}>
                  {data.filter((list) => list.Department === "Service & Support").length} Openings
                </div>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="row sm-pt3"
            style={{ padding: "70px 0px 10px 0px" }}
          >
            <div className="col-lg-6 col-12 col-md-6">
              <div className="thirty" style={{ color: "#111111" }}>
                <b className="sm-25">All Openings ({data.length})</b>
              </div>
            </div>
            {/*  <div className="col-lg-6 col-12 col-md-6 sm-mt1">
              <div className="d-flex align-items-center justify-content-end gap-1 display-start" style={{ gap: "22px" }}>
                <div style={{ fontSize: "22px" }}>
                  <b className="sm-16">Sort by :</b>
                </div>
                <div style={{ fontSize: "22px", color: "#555554" }} className="sm-16">
                  Engineering & Technology
                </div>
                <BiChevronDown style={{ color: "#1F568C", fontSize: "35px" }} />
              </div>
            </div> */}
          </div>

          <div className="row" style={{ marginBottom: "157px" }}>
            {data.map((data) => (
              <div className="col-lg-4 col-md-4 col-12 mt-5" data-aos="fade-up">
                <div className="career_box_02 d-flex justify-items-end flex-column">
                  <div style={{ fontSize: "24px" }}>
                    {data.Department} - {data.Degination}
                  </div>

                  <div
                    className="d-flex align-items-center mt-4"
                    style={{ gap: "5px", color: "#555554" }}
                  >
                    <div style={{ fontSize: "18px" }}>{data.noofvacancy} Opening Available</div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-4 mb-auto"
                    style={{ gap: "5px", color: "#555554" }}
                  >
                    <BiCurrentLocation style={{ fontSize: "18px" }} />
                    <div style={{ fontSize: "18px" }}>{data.location}</div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="btn btn_apply" onClick={() => navigate(`/jobDetails/${data._id}`)}>View</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          style={{ backgroundColor: "#F2F5F8", position: "relative" }}
          data-aos="fade-up"
        >
          <div className="l_e_c_1 sm-none">
            <img src={Group_74103} alt="Error" />
          </div>
          <div className="l_e_c_2 sm-none">
            <img src={Group_54508} alt="Error" />
          </div>
          <div className="container">
            <div style={{ padding: "80px 0px", position: "relative" }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <form style={{ width: "90%" }} onSubmit={contactSubmit}>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ height: "" }}
                  >
                    <div style={{ fontSize: "30px" }}>
                      <b className="sm-25">Fill the form</b>
                    </div>

                    <div className="join-1">
                      Couldn't find what you were looking for?
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 col-lg-6 mt-4">
                      <div className="form-group">
                        <input
                          className="form-control join_form"
                          data-aos="fade-right"
                          type="text"
                          placeholder="Your full name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4">
                      <div className="form-group">
                        <select name="department" id="depart"
                          placeholder='Department'
                          className="form-control join_form1"
                          data-aos="fade-right"
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}>
                          <option value="">Select area of interest</option>
                          <option>Marketing & Sales</option>
                          <option>Accounting & Finance</option>
                          <option>Operations & Distribution</option>
                          <option>Technology Solutions</option>
                          <option>HR & Admin</option>
                          <option>Service & Support</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4">
                      <div className="form-group">
                        <input
                          className="form-control join_form"
                          data-aos="fade-right"
                          type="email"
                          placeholder="Your email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4">
                      <div className="form-group">
                        <input
                          className="form-control join_form"
                          data-aos="fade-left"
                          type="text"
                          placeholder="Your mobile number"
                          maxLength='10'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 mt-4">
                      <div className="form-group">
                        <textarea
                          rows="4"
                          className="form-control join_form"
                          data-aos="fade-up"
                          type="text"
                          placeholder="Type your message here."
                          maxLength='250'
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "7px" }}
                  >

                    <BiLinkAlt style={{ fontSize: "22px" }} />
                    <div className="mt-1" style={{ fontSize: "16px", color: "#00155A" }} >
                      <label htmlFor="file1" style={{ cursor: 'pointer' }} >
                        {imageUpload ? 'File Selected' : 'Attach Resume, Cover Letter etc'}
                      </label>
                      <input type="file" id="file1" accept="pdf/*" style={{ visibility: 'hidden' }} onChange={(event) => {
                        setImageUpload(event.target.files[0]);
                      }} />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ padding: "22px 0px 50px 0px" }}
                  >
                    <button className="btn btn-contact" data-aos="flip-right">
                      <div className="d-flex justify-content-center aling-items-center">
                        <div>Send</div>
                        <div className="ml-2">
                          <FaTelegramPlane style={{ fontSize: "17px" }} />
                        </div>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_up">
        <div className="sm-none">
          <Footer />
        </div>
        <div className="lg-none">
          <FooterMob />
        </div>
      </div>
    </>
  );
};

export default Careers;
