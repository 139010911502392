import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

// Images
import osg_logo from "../../Images/osg_logo.png";
import Component_235_1 from '../../Images/Component_235_1.png'

// Icons
import { BiChevronDown, BiChevronUp, BiMenu } from 'react-icons/bi'
import { useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
const NewNavBar = ({ hide1value, hide2value }) => {

    const navigate = useNavigate();
    const [toggler, setToggler] = useState(false);
    const [hide, setHide] = useState(hide1value);
    const [hide2, setHide2] = useState(hide2value)
    const admin = localStorage.getItem('admin');
    console.log(admin)
    useEffect(() => {
        setHide(0)
        setHide2(0)
    }, [])

    const ToggleHandler = () => {
        if (toggler) {
            setToggler(false);
        } else {
            setToggler(true);
        }
    }
    const logout = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            <nav className="navbar_css sm-none" style={{ height: '80px' }}>
                <div className="containerHeader" >
                    <div id="navbar">
                        <div className="res_menu">
                            <div id="mobile" onClick={ToggleHandler}>
                                {toggler ? <IoMdClose className='toggle-icons mr-2' /> : <BiMenu className='toggle-icons mr-2' />}
                            </div>
                            <div className="res_logo">
                                <img onClick={() => navigate('/')} src={osg_logo} className="main-logo" width="174px" height="53px" alt="Logo" style={{ cursor: "pointer" }} />
                            </div>
                        </div>
                        <div className='d-flex justify-content-between sm-none' style={{ marginLeft: '30%', width: '40%', fontSize: '18px', fontWeight: 'bold', color: '#00155a' }}>
                            <div style={{ cursor: 'pointer' }} onMouseOver={() => setHide(1)} onClick={() => setHide(1)}>Solution{hide === 1 ? <MdKeyboardArrowUp size='25px' /> : <MdKeyboardArrowDown size='25px' />}</div>

                            {hide === 1 &&
                                <div className='nav_drop_box' onMouseLeave={() => { setHide(0); setHide2(0) }}>
                                    <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { hide2 === 1 ? setHide2(0) : setHide2(1) }} >Cyber Security{hide2 === 1 ? <MdKeyboardArrowUp size='20px' /> : <MdKeyboardArrowDown size='20px' />}</div>
                                    {hide2 === 1 &&
                                        <>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/cyberSecurity/network-perimeter'); setHide2(0) }} >Network and Perimeter Security</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/cyberSecurity/cloud-security'); setHide2(0) }} >Cloud Security</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/cyberSecurity/application-security'); setHide2(0) }} >Application Security</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/cyberSecurity/data-security'); setHide2(0) }} >Data Security</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/cyberSecurity/mobile-security'); setHide2(0) }} >Mobile Security</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/cyberSecurity/endPoint-security'); setHide2(0) }} >End Point Security</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/cyberSecurity/operation-security'); setHide2(0) }} >Security Operations</div>

                                        </>
                                    }
                                    <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { hide2 === 2 ? setHide2(0) : setHide2(2) }} >Intrastructure Modernization{hide2 === 2 ? <MdKeyboardArrowUp size='20px' /> : <MdKeyboardArrowDown size='20px' />}</div>
                                    {hide2 === 2 &&
                                        <>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/infrastructure/smart-data'); setHide2(0) }} >Smart Data Centres</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/infrastructure/networking'); setHide2(0) }} >Networking</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/infrastructure/boardroom-solution'); setHide2(0) }} >Boardroom Solutions</div>
                                            <div className='dropdown-item ml-2' style={{ cursor: 'pointer' }} onClick={() => { navigate('/infrastructure/edge-computing'); setHide2(0) }} >Edge Computing</div>

                                        </>
                                    }
                                    <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { navigate('/product&service/software-solutions'); setHide(0) }} >Software Solutions</div>
                                    <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { navigate('/product&service/endpoints'); setHide(0) }} >Intelligent Devices</div>
                                    <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { navigate('/product&service/mobility-solutions'); setHide(0) }} >Mobility</div>
                                    <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { navigate('/product&service/services-solutions'); setHide(0) }} >Services</div>
                                </div>
                            }

                            <div style={{ cursor: 'pointer' }} onMouseOver={() => setHide(2)} onClick={() => setHide(2)}>Company{hide === 2 ? <MdKeyboardArrowUp size='25px' /> : <MdKeyboardArrowDown size='25px' />}</div>
                            {hide === 2 &&
                                <div className='nav_drop_box2' onMouseLeave={() => setHide(0)}>
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { navigate('/about'); setHide(0) }} >About us</div>
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' ><a href="https://isource.in/omsai/index.html" onClick={() => { setHide(0); setToggler(false) }} >Marketing</a></div>
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { navigate('/leadership_team'); setHide(0) }} >Leadership Team</div>
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { navigate('/case-study'); setHide(0) }} >Case Studies</div>
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { navigate('/awards&recogination'); setHide(0) }} >Awards & Recognisations</div>
                                </div>
                            }
                            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/contact')} >Contact us</div>
                            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/careers')} >Career</div>
                        </div>
                        <div className='sm-none' style={{ position: "relative" }}>
                            <div className="d-flex align-items-center" onClick={() => { hide === 4 ? setHide(0) : setHide(4) }} >
                                <img src={Component_235_1} alt="profile" width="50px" height="50px" />
                                {hide === 4 ?
                                    <BiChevronUp style={{ color: "#00155A", fontSize: "22px" }} /> :
                                    <BiChevronDown style={{ color: "#00155A", fontSize: "22px" }} />
                                }
                            </div>
                        </div>
                        {hide === 4 &&
                            <div className='nav_drop_box2 sm-none' onMouseLeave={() => setHide(0)} style={{ right: '10px' }}>
                                {admin === 'yes' ?
                                    <>
                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { setHide(0); navigate('/contactDetails'); }} >Requested ContactUs</div>
                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { setHide(0); navigate('/candidate'); }} >Candidate</div>
                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { setHide(0); navigate('/dashboard'); }} >Added Vacancy</div>
                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { setHide(0); navigate('/add-vacancy'); }} >Add New Vacancy</div>
                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { setHide(0); logout(); }} >Logout</div>
                                    </>
                                    :
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='dropdown-item' onClick={() => { navigate('/login'); setHide(0) }} >Login</div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </nav>
            <div className='navbar_css nav_list active-navbar lg-none' >
                <div className='d-flex justify-content-between align-items-center ml-3 mr-3'>
                    <div className="res_logo">
                        <img onClick={() => navigate('/')} src={osg_logo} className="main-logo" width="174px" height="53px" alt="Logo" style={{ cursor: "pointer" }} />
                    </div>
                    <div >
                        <div onClick={ToggleHandler}>
                            {toggler ? <IoMdClose className='toggle-icons mr-2' /> : <BiMenu className='toggle-icons mr-2' />}
                        </div>
                    </div>
                </div>
                {toggler &&
                    <>

                        <div className='dropdown-item mt-2'
                            style={hide === 1 ? { fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderBottom: '1px solid black', color: '#fff', backgroundColor: '#C51213' }
                                : { fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderBottom: '1px solid black', }}
                            onClick={() => { setHide(hide === 1 ? 0 : 1) }} >
                            Solutions{hide === 1 ? <MdKeyboardArrowUp size='20px' style={{ right: '10px' }} /> : <MdKeyboardArrowDown size='20px' style={{ right: '10px' }} />}</div>
                        {hide === 1 &&
                            <>
                                <div className='dropdown-item ' style={{ fontWeight: 'bold', backgroundColor: '#fff', cursor: 'pointer', padding: '20px' }} onClick={() => { hide2 === 1 ? setHide2(0) : setHide2(1) }} >Cyber Security{hide2 === 1 ? <MdKeyboardArrowUp size='20px' style={{ right: '10px' }} /> : <MdKeyboardArrowDown size='20px' style={{ right: '10px' }} />}</div>
                                {hide2 === 1 &&
                                    <>
                                        <div className='dropdown-item' style={{ backgroundColor: '#fff', paddingTop: '5px' }} onClick={() => { navigate('/cyberSecurity/network-perimeter'); setHide2(0); setToggler(false) }} >Network and Perimeter Security</div>
                                        <div className='dropdown-item' style={{ backgroundColor: '#fff', paddingTop: '5px' }} onClick={() => { navigate('/cyberSecurity/cloud-security'); setHide2(0); setToggler(false) }} >Cloud Security</div>
                                        <div className='dropdown-item' style={{ backgroundColor: '#fff', paddingTop: '5px' }} onClick={() => { navigate('/cyberSecurity/application-security'); setHide2(0); setToggler(false) }} >Application Security</div>
                                        <div className='dropdown-item' style={{ backgroundColor: '#fff', paddingTop: '5px' }} onClick={() => { navigate('/cyberSecurity/data-security'); setHide2(0); setToggler(false) }} >Data Security</div>
                                        <div className='dropdown-item' style={{ backgroundColor: '#fff', paddingTop: '5px' }} onClick={() => { navigate('/cyberSecurity/mobile-security'); setHide2(0); setToggler(false) }} >Mobile Security</div>
                                        <div className='dropdown-item' style={{ backgroundColor: '#fff', paddingTop: '5px' }} onClick={() => { navigate('/cyberSecurity/endPoint-security'); setHide2(0); setToggler(false) }} >End Point Security</div>
                                        <div className='dropdown-item' style={{ backgroundColor: '#fff', paddingTop: '5px', paddingBottom: '10px', borderBottom: '0.5px solid #1976bc' }}
                                            onClick={() => { navigate('/cyberSecurity/operation-security'); setHide2(0) }} >Security Operations</div>
                                    </>
                                }
                                <div className='dropdown-item ' style={{ fontWeight: 'bold', backgroundColor: '#fff', cursor: 'pointer', padding: '20px' }} onClick={() => { hide2 === 2 ? setHide2(0) : setHide2(2) }} >Intrastructure Modernization{hide2 === 2 ? <MdKeyboardArrowUp size='20px' /> : <MdKeyboardArrowDown size='20px' />}</div>
                                {hide2 === 2 &&
                                    <>
                                        <div className='dropdown-item ' style={{ cursor: 'pointer', paddingTop: '5px', backgroundColor: '#fff', }} onClick={() => { navigate('/infrastructure/smart-data'); setHide2(0); setToggler(false) }} >Smart Data Centres</div>
                                        <div className='dropdown-item ' style={{ cursor: 'pointer', paddingTop: '5px', backgroundColor: '#fff', }} onClick={() => { navigate('/infrastructure/networking'); setHide2(0); setToggler(false) }} >Networking</div>
                                        <div className='dropdown-item ' style={{ cursor: 'pointer', paddingTop: '5px', backgroundColor: '#fff', }} onClick={() => { navigate('/infrastructure/boardroom-solution'); setHide2(0); setToggler(false) }} >Boardroom Solutions</div>
                                        <div className='dropdown-item ' style={{ backgroundColor: '#fff', paddingTop: '5px', paddingBottom: '10px', borderBottom: '0.5px solid #1976bc', cursor: 'pointer' }}
                                            onClick={() => { navigate('/infrastructure/edge-computing'); setHide2(0); setToggler(false) }} >Edge Computing</div>

                                    </>
                                }
                                <div className='dropdown-item' style={{ fontWeight: 'bold', backgroundColor: '#fff', cursor: 'pointer', padding: '20px' }} onClick={() => { navigate('/product&service/software-solutions'); setHide(0); setToggler(false) }} >Software Solutions</div>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', backgroundColor: '#fff', cursor: 'pointer', padding: '20px' }} onClick={() => { navigate('/product&service/endpoints'); setHide(0); setToggler(false) }} >Intelligent Devices</div>
                                <div className='dropdown-item ' style={{ fontWeight: 'bold', backgroundColor: '#fff', padding: '20px', cursor: 'pointer' }} onClick={() => { navigate('/product&service/mobility-solutions'); setHide(0); setToggler(false) }} >Mobility</div>
                                <div className='dropdown-item ' style={{ fontWeight: 'bold', backgroundColor: '#fff', padding: '20px', cursor: 'pointer', }} onClick={() => { navigate('/product&service/services-solutions'); setHide(0); setToggler(false) }} >Services</div>

                            </>

                        }
                        <div className='dropdown-item' style={hide === 2 ? { fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderBottom: '1px solid black', color: '#fff', backgroundColor: '#C51213' }
                            : { fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderBottom: '1px solid black', }} onClick={() => { setHide(hide === 2 ? 0 : 2); }} >Company{hide === 2 ? <MdKeyboardArrowUp size='20px' style={{ right: '10px' }} /> : <MdKeyboardArrowDown size='20px' style={{ right: '10px' }} />}</div>
                        {hide === 2 &&
                            <>
                                <div className='dropdown-item ' style={{ fontWeight: 'bold', cursor: 'pointer', paddingTop: '5px', backgroundColor: '#fff', padding: '20px', }} onClick={() => { navigate('/about'); setHide(0); setToggler(false) }} >About us</div>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', backgroundColor: '#fff', }} ><a href="https://isource.in/omsai/index.html" onClick={() => { setHide(0); setToggler(false) }} >Marketing</a></div>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', backgroundColor: '#fff', }} onClick={() => { navigate('/leadership_team'); setHide(0); setToggler(false) }} >Leadership Team</div>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', backgroundColor: '#fff', }} onClick={() => { navigate('/case-study'); setHide(0); setToggler(false) }} >Case Studies</div>
                                <div className='dropdown-item ' style={{ fontWeight: 'bold', padding: '20px', cursor: 'pointer', backgroundColor: '#fff', }} onClick={() => { navigate('/awards&recogination'); setHide(0); setToggler(false) }} >Awards & Recognisations</div>
                            </>
                        }
                        <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderBottom: '1px solid black', }} onClick={() => { navigate('/contact'); setHide(0); setToggler(false) }} >Contact us</div>
                        <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', }} onClick={() => { navigate('/careers'); setHide(0); setToggler(false) }} >Career</div>
                        {admin === 'yes' ?
                            <>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderTop: '1px solid black', }} onClick={() => { setHide(0); navigate('/contactDetails'); setToggler(false) }} >Requested ContactUs</div>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderTop: '1px solid black', }} onClick={() => { setHide(0); navigate('/candidate'); setToggler(false) }} >Candidate</div>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderTop: '1px solid black', }} onClick={() => { setHide(0); navigate('/dashboard'); setToggler(false) }} >Added Vacancy</div>
                                <div className='dropdown-item' style={{ fontWeight: 'bold', cursor: 'pointer', padding: '20px', borderTop: '1px solid black', }} onClick={() => { setHide(0); navigate('/add-vacancy'); setToggler(false) }} >Add New Vacancy</div>

                                <div style={{ position: "relative" }} >
                                    {/*  onClick={() => { hide === 4 ? setHide(0) : setHide(4) }} */}
                                    <div className="d-flex align-items-center mt-3 mb-2 ml-2" style={{ paddingTop: '2px', borderRadius: '10px', backgroundColor: '#fff', border: '1px solid black', width: '150px', height: '50px', }} onClick={() => { logout(); setHide(0); setToggler(false) }}  >
                                        <img className='mr-3' src={Component_235_1} alt="profile" width="50px" height="50px" /> Logout
                                    </div>
                                </div>
                            </>
                            :
                            <div style={{ position: "relative" }} >
                                {/*  onClick={() => { hide === 4 ? setHide(0) : setHide(4) }} */}
                                <div className="d-flex align-items-center mt-3 mb-2 ml-2" style={{ paddingTop: '2px', borderRadius: '10px', backgroundColor: '#fff', border: '1px solid black', width: '150px', height: '50px', }} onClick={() => { navigate('/login'); setHide(0); setToggler(false) }} >
                                    <img className='mr-3' src={Component_235_1} alt="profile" width="50px" height="50px" /> Login
                                </div>
                                <div className={hide === 6 ? "nav_drop_box" : "hide"} style={{ marginTop: '5px', right: '-30px' }}>
                                    {/* onClick={() => setHide(0)} */}


                                </div>
                            </div>
                        }

                    </>
                }
            </div>
        </>
    )
}

export default NewNavBar 