import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/CampusNetworking.png'
import datacenter from '../../Images/Techigent/DataCentreNetworking.jpg'
import wifi from '../../Images/Techigent/Wi-FiandAps.jpg'
import sdwan from '../../Images/Techigent/SDWAN.png'
import passive from '../../Images/Techigent/Passivenetworking.jpg'
import FooterMob from '../Layout/FooterMob'

const Networking = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Infrastructure Modernization" title="Cloud, Security, Security & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/smart-data')}><b>Smart Data Centres</b></div>
                            <div className='mt-4' style={{ color: "#C51213", paddingRight: '20px', borderBottom: "2px solid #C51213", paddingBottom: "40px" }} onClick={() => navigate('/infrastructure/networking')}><b>Networking</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/boardroom-solution')}><b>Boardroom Solutions</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/edge-computing')}><b>Edge Computing</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Campus Networking</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A campus network is a proprietary local area network (LAN) or set of interconnected LANs serving a corporation, government agency, university, or similar organization. All of the nodes in a campus network are interconnected by means of optical fiber media, taking advantage of gigabit Ethernet or 10-Gigabit Ethernet technology. In some cases, Wi-Fi hot spots or even a hot zone make up the user end of the network.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={datacenter} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Data Centre Networking </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Data center networking is the integration of a constellation of networking resources — switching, routing, load balancing, analytics, etc. — to facilitate the storage and processing of applications and data. Data center networking is the process of establishing and interconnecting the entire physical and network-based devices and equipment within a data center facility.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={datacenter} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={wifi} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Wi-Fi and Aps</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We offers simple-to-deploy, flexible, and secure wireless solutions designed to enable everyone to communicate and collaborate.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={sdwan} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>SD-WAN</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A Software-defined Wide Area Network (SD-WAN) is a virtual WAN architecture that allows enterprises to leverage any combination of transport services – including MPLS, LTE and broadband internet services – to securely connect users to applications.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={sdwan} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>


                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={passive} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Passive networking</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We do provide passive networking solution for the large projects.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Networking