import React from 'react'
import "@fontsource/poppins";
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// CSS
import './App.css'

// Layout
// import Navbar from './components/Layout/Navbar'
import NewNavBar from './components/Layout/NewNavBar'
import Home from './components/Layout/Home'
import Careers from './components/Layout/Careers'
import Contact from './components/Layout/Contact'
import Awards from './components/Layout/Awards'
import Gallery from './components/Layout/Gallery'
import Detail from './components/Layout/Detail'
import LeaderTeam from './components/Layout/LeaderTeam'
import LeaderDetail from './components/Layout/LeaderDetail'
import Blogs from './components/Layout/Blogs'
import About from './components/Layout/About'
import News from './components/Layout/News'
import CaseStudy from './components/Layout/CaseStudy'

// Product And Service
import ProdAndServ from './components/Service/ProdAndServ'
import Endpoints from './components/Service/Endpoints'
import HardwareSol from './components/Service/HardwareSol'
import SoftwareSol from './components/Service/SoftwareSol'
import NetworkPerimeter from './components/Service/NetworkPerimeter'
import CloudSecurity from './components/Service/CloudSecurity'
import ApplicationSecurity from './components/Service/ApplicationSecurity'
import DataSecurity from './components/Service/DataSecurity'
import MobileSecurity from './components/Service/MobileSecurity'
import EndPointSecurity from './components/Service/EndPointSecurity'
import OperationsSecurity from './components/Service/OperationsSecurity'
import SmartDataCentres from './components/Service/SmartDataCentres'
import Networking from './components/Service/Networking'
import BoardroomSolutions from './components/Service/BoardroomSolutions'
import EdgeComputing from './components/Service/EdgeComputing'
import Mobility from './components/Service/Mobility'
import Services from './components/Service/Services'
import CaseStudieDetails from './components/Layout/CaseStudieDetails';
import ScrollToTop from './components/Layout/ScrollToTop';
import Login from './components/Layout/Login';
import Dashboard from './components/Layout/Dashboard';
import AddVacancy from './components/Layout/AddVacancy';
import JobDetails from './components/Layout/JobDetails';
import ContactusDetails from './components/Layout/ContactusDetails';
import Applied from './components/Layout/Applied';
import Transvation from './components/Layout/Transvation';

const App = () => {

  return (
    <>
      <BrowserRouter>

        <ScrollToTop />
        <NewNavBar hide1value={0} hide2value={0} />
        {/* <Navbar /> */}
        <Routes>

          <Route exact path='/' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/jobDetails/:id' element={<JobDetails />} />
          <Route exact path='/contactDetails' element={<ContactusDetails />} />
          <Route exact path='/candidate' element={<Applied />} />
          <Route exact path='/add-vacancy' element={<AddVacancy />} />
          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route exact path='/transvation-event' element={<Transvation />} />
          <Route exact path='/careers' element={<Careers />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/awards&recogination' element={<Awards />} />
          <Route exact path='/gallery' element={<Gallery />} />
          <Route exact path='/detail' element={<Detail />} />
          <Route exact path='/leadership_team' element={<LeaderTeam />} />
          <Route exact path='/leaderDetail/:id' element={<LeaderDetail />} />
          <Route exact path='/product&service' element={<ProdAndServ />} />
          <Route exact path='/product&service/endpoints' element={<Endpoints />} />
          <Route exact path='/product&service/hardware-solutions' element={<HardwareSol />} />
          <Route exact path='/product&service/software-solutions' element={<SoftwareSol />} />
          <Route exact path='/product&service/mobility-solutions' element={<Mobility />} />
          <Route exact path='/product&service/services-solutions' element={<Services />} />
          <Route exact path='/cyberSecurity/network-perimeter' element={<NetworkPerimeter />} />
          <Route exact path='/cyberSecurity/cloud-security' element={<CloudSecurity />} />
          <Route exact path='/cyberSecurity/application-security' element={<ApplicationSecurity />} />
          <Route exact path='/cyberSecurity/data-security' element={<DataSecurity />} />
          <Route exact path='/cyberSecurity/mobile-security' element={<MobileSecurity />} />
          <Route exact path='/cyberSecurity/endPoint-security' element={<EndPointSecurity />} />
          <Route exact path='/cyberSecurity/operation-security' element={<OperationsSecurity />} />
          <Route exact path='/infrastructure/smart-data' element={<SmartDataCentres />} />
          <Route exact path='/infrastructure/networking' element={<Networking />} />
          <Route exact path='/infrastructure/boardroom-solution' element={<BoardroomSolutions />} />
          <Route exact path='/infrastructure/edge-computing' element={<EdgeComputing />} />
          <Route exact path='/blogs&post' element={<Blogs />} />
          <Route exact path='/news' element={<News />} />
          <Route exact path='/case-study' element={<CaseStudy />} />
          <Route exact path='/case-study/details' element={<CaseStudieDetails />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App