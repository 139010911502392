import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/ManagedServices.jpg'
import database from '../../Images/Techigent/DeviseasaService(DAAS).jpg'
import security from '../../Images/Techigent/FacilityManagementServices.jpg'
import device from '../../Images/Techigent/AnnualMaintenanceContract(AMC).jpeg'
import virtualci from '../../Images/Techigent/VirtualCISOServices.jpg'
import virtual from '../../Images/virtual.png'
import deplo from '../../Images/Techigent/DeploymentServices.jpg'
import backup from '../../Images/Techigent/MailandMassaging.jpg'
import os from '../../Images/Techigent/SpecialisedOncallSupport.jpg'
import manage from '../../Images/manage.png'
import sap from '../../Images/Techigent/ServiceDeskManagement.jpg'
import FooterMob from '../Layout/FooterMob'
import { useState } from 'react'

const Services = () => {
    const [viewMore, setViewMore] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Services" title="Managed Services, DAAS,FMS & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/software-solutions')}><b>Software Solutions</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/endpoints')}><b>Intelligent Devices</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/mobility-solutions')}><b>Mobility</b></div>
                            <div style={{ color: "#C51213", paddingRight: '20px', borderBottom: "2px solid #C51213", paddingBottom: "20px" }} className='mt-4' onClick={() => navigate('/product&service/services-solutions')}><b>Services</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Managed Services</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We provide SLA based managed services to our customers for managing their data centres operations, Networks, Helpdesk and Ticket management, Patch Management, End Point management. Etc
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Devise as a Service (DAAS)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                For organizations large and small, refreshing a fleet of personal computing devices every three to four years can involve substantial costs, especially when considering peripheral expenditures for procurement, deployment, training, support, recovery, and asset management.
                                {viewMore === 1 ? "Device as a Service (DaaS) helps organizations mitigate these costs by taking a typical hardware device (such as a laptop, desktop, tablet, or mobile phone), bundling it with a variety of services and software, and offering it to a customer for a monthly subscription fee. It gives customers one contract and just one provider to hold accountable." :
                                    <div className="btn btn_know_more_ep mt-4 d-flex align-items-center justify-content-center sm-mb3" onClick={() => setViewMore(1)}>Know more</div>
                                }
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={security} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Facility Management Services (FMS)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We provide trained resources on contract basis to manage your endpoints operations, Data Center Operations, Network Operations, Asset and helpdesk management and Patch Management. End point imaging and Security as per defined SLAs.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Annual Maintenance Contract (AMC)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We provide AMC for endpoints, Data Center Infrastructure and Networking components. We do provide BTB and third-party support.
                            </p>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={virtual} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>SOC as a Services</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A number of different factors impact an organization’s ability to operate an effective and mature SOC in-house. The cybersecurity talent shortage makes skilled personnel difficult and expensive to attract and retain.
                                {viewMore === 2 ? " Additionally, effective cybersecurity requires investment in an array of security solutions designed to address and mitigate a wide variety of potential threats.SOC-as-a-Service offerings allow an organization to outsource their security responsibilities to a third-party provider. Instead of manning a full-service SOC in-house, the SOC-as-a-Service provider takes responsibility for the round-the-clock networking monitoring and defense required to protect against modern cyber threats." :
                                    <div className="btn btn_know_more_ep mt-4 d-flex align-items-center justify-content-center sm-mb3" onClick={() => setViewMore(2)}>Know more</div>
                                }
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={virtualci} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Virtual CISO Services </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A virtual CISO is a service that replicates the job functions of a Chief Information Security Officer (CISO) creating and managing cybersecurity and other relevant policies for an organization—effectively outsourcing the role but not on formal employment of the organization.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={virtualci} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>


                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={deplo} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Deployment Services </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We have well experienced team of IT professionals to execute the implementation projects as per pre-defined SOWs and timelines. Our team analyse the requirement and plan with timelines and defined ownership to complete the project. We provide implementation services for Cyber Security Solution, Infrastructure Hardware, and Software Solutions.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-up">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={os} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Specialised On call Support</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We provide On call based specialised services support. It gives customer flexibility to choose the right fit for their needs.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={os} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={sap} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Service Desk Management</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                It helps customers to manage service operations and ensure guaranteed service delivery as per defined service level goals. It enables you to implement ITIL best practices for incident, problem, change and service fulfilment processes to make the service delivery standardized and agile.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-up">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={manage} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Patch Management</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Patch management is the subset of systems management that involves identifying, acquiring, testing and installing patches, or code changes, that are intended to fix bugs, close security holes or add features. Patch management helps keep computers and networks secure, reliable and up to date with features and functionality that the organization considers important.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={manage} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={backup} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Mail and Massaging </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We consult our customer in getting right collaboration and productivity platform as per their requirement and budgets. Being a Microsoft partner, we help customers to migrate from any platform to M365.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services