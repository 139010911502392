import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/NextGenFirewall(NGFW).jpg'
import database from '../../Images/storage.png'
import security from '../../Images/Techigent/HardwareSecurityModule(HSM).jpg'
import device from '../../Images/device.png'
import virtual from '../../Images/virtual.png'
import backup from '../../Images/Techigent/PrivilegedAccessManagement(PAM).png'
import FooterMob from '../Layout/FooterMob'

const NetworkPerimeter = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Cyber Security" title="Cloud, Security, Security & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", paddingRight: '20px', marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ color: "#C51213", paddingRight: '20px', borderBottom: "2px solid #C51213", paddingBottom: "20px" }} onClick={() => navigate('/cyberSecurity/network-perimeter')}><b>Network and Perimeter Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/cloud-security')}><b>Cloud Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/application-security')}><b>Application Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/data-security')}><b>Data Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/mobile-security')}><b>Mobile Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/endPoint-security')}><b>End Point Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/operation-security')}><b>Security Operations</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Next Gen Firewall (NGFW)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Protects on-premise networks from attacks through features like Firewall, URL/App Filtering, IPS, Antibot, AntiVirus, VPN, Sandboxing etc</p>

                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Intrusion Prevention System (IPS) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>It continuously monitors a network for malicious activity and takes action to prevent it, including reporting, blocking, or dropping it</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Intrusion Prevention System (IPS) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>It continuously monitors a network for malicious activity and takes action to prevent it, including reporting, blocking, or dropping it</p>
                        </div>
                    </div>


                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>SSL VPN </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Helps users to connect users remotely to office network</p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={backup} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Privileged Access Management (PAM) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Secures and protects privileges across passwords, endpoints, and access, giving organizations the visibility and control they need to reduce risk, achieve compliance, and boost operational performance.</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={backup} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={backup} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Privileged Access Management (PAM) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Secures and protects privileges across passwords, endpoints, and access, giving organizations the visibility and control they need to reduce risk, achieve compliance, and boost operational performance.</p>
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>SSL VPN </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Helps users to connect users remotely to office network</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={virtual} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Email Security</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Protects users from spam, phishing link, spoofing, malicious attachments etc</p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={security} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Advance Threat Prevention (ATP) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Dedicated On-Prem Appliance which helps organisations fight against Zero Day Threat/Advance Persistent Threat</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={security} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NetworkPerimeter