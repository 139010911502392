import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/VirtualDesktopInfrastructure.jpg'
import database from '../../Images/Techigent/Digitalworkspace.jpg'
import delivery from '../../Images/Techigent/ApplicationDelivery.jpg'
import device from '../../Images/Techigent/MobileApplicationManagement.jpg'
import FooterMob from '../Layout/FooterMob'

const Mobility = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Mobility" title="Virtual Desktop, Digital workspace & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/software-solutions')}><b>Software Solutions</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/endpoints')}><b>Intelligent Devices</b></div>
                            <div style={{ color: "#C51213", paddingRight: '20px', borderBottom: "2px solid #C51213", paddingBottom: "20px" }} className='mt-4' onClick={() => navigate('/product&service/mobility-solutions')}><b>Mobility</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/services-solutions')}><b>Services</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Virtual Desktop Infrastructure</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Virtual Desktop Infrastructure (VDI) is a technology that refers to the use of virtual machines to provide and manage virtual desktops. VDI hosts desktop environments on a centralized server and deploys them to end-users on request.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Digital workspace</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A digital workspace is a technology framework that manages and centrally controls the elements of an organization’s IT assets including applications, data, and endpoints. A digital workspace also provides anytime, anywhere, any device access to applications for users, whether those applications reside on-premises or on one or more clouds.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={delivery} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Application Delivery</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                App Delivery is a fully managed application streaming service that provides users instant access to their desktop applications from anywhere. It simplifies application management, improves security, and reduces costs by moving a company's applications from their users' physical devices to the Cloud.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ fontSize: "32px" }}><b className='sm-25'>Mobile Application Management </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Mobile application management (MAM) is software that secures and enables IT control over enterprise applications on end users' corporate and personal smartphones and tablets. MAM software allows IT administrators to apply and enforce corporate policies on mobile apps and limit the sharing of corporate data among apps. It also enables the separation of business apps and data from personal content on the same device.
                            </p>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mobility