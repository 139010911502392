import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "@fontsource/poppins";
import Techigent_2021 from '../../OSG-2021_002_optimize.pdf'

import Component_225_1 from "../../Images/Group_74145.svg";

import Cyber from '../../Images/solutionsImg/Cybersecurity.jpg'
import inno from '../../Images/solutionsImg/innovation-technology-business-finance-background.jpg'
import Intelligent from '../../Images/solutionsImg/Intelligent.jpg'
import infrastructure from '../../Images/solutionsImg/it-infrastructure-operations-management.jpg'
import Mobility from '../../Images/solutionsImg/Mobility.jpg'
import Softwares from '../../Images/solutionsImg/Softwares.jpg'

import IMG2000 from "../../Images/aword/IMG_2000.JPG";
import IMG2002 from "../../Images/aword/IMG_2002.JPG";
import IMG2003 from "../../Images/aword/IMG_2003.JPG";
import IMG2006 from "../../Images/aword/IMG_2006.JPG";
import IMG2008 from "../../Images/aword/IMG_2008.JPG";
import IMG2010 from "../../Images/aword/IMG_2010.JPG";
import IMG2011 from "../../Images/aword/IMG_2011.JPG";
import IMG2013 from "../../Images/aword/IMG_2013.JPG";
import IMG2015 from "../../Images/aword/IMG_2015.JPG";

// Components
import MainHeader from "../Layout/MainHeader";
import Footer from "../Layout/Footer";
import FooterMob from "../Layout/FooterMob";

// Images
import noun from "../../Images/noun-trust-2714621.svg";
import Component_237_1 from "../../Images/Component_228_1.svg";
import Component_238_1 from "../../Images/Component_229_2.svg";
import Component_239_1 from "../../Images/Component_230_12.svg";
import download from "../../Images/download.svg";
import Component_229_1 from "../../Images/Component_229_1.svg";
import Component_226_5 from "../../Images/Component_226_5.svg";
import { FaDownload } from "react-icons/fa";

// Icons
// import { BiChevronRight } from 'react-icons/bi'

// Dummy Image
// const dummy_img = "https://images.unsplash.com/photo-1542744095-291d1f67b221?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGxhcHRvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
const user =
  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";

const About = () => {
  const navigate = useNavigate();
  const [awords, setAwords] = useState(1)
  const [knowMore, setKnowMore] = useState(false)

  useEffect(() => {
    Aos.init({ duration: 1000, delay: 90 });
  }, []);
  return (
    <>
      <div>
        <MainHeader
          img="c_img_about sm-carrer-img"
          name="The Company"
          title="Growing since 2000"
        />
        <div className="container sm-mt-1" style={{ position: "relative" }}>
          <img src={Component_226_5} alt="logo" className="car_effect_04 sm-none" />

          <div className="l_e_c_2 sm-none">
            <img src={Component_225_1} alt="Error" />
          </div>
          <div style={{ position: "relative" }} data-aos="fade-up">
            <div>
              <div style={{ padding: "55px 0px 30px 0px" }}>
                <div
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                  }}
                  className="sm-25"
                >
                  About Us
                </div>
                <div style={{ fontSize: "18px", color: "#555554" }} className="sm-15">
                  Growing since 2000
                </div>
              </div>
              <div style={{ color: "#111111" }}>
                Om Sai Group has over 20 years of experience in the IT Industry and its headquarters in Mumbai with PAN India presence in sales and services.<br />
                With a proven portfolio in IT solution, products and services, OSG has been delivering IT infrastructure - Products, Service & Solutions to a wide range of industry verticals including- Banking & Finance Services, Retail Distribution, Pharmaceuticals, Utilities and Real Estate. Its ready to deploy product portfolio comprises of turnkey IT solutions that help new age businesses leverage varied set of business data & information.<br />
                {knowMore &&
                  <>
                    <br />
                    We constantly study the IT industry trends and keep an eye on solutions that fit market niches We review the products strengths and market standing before introducing them to the customer.
                    <br /><br />
                    The decade long journey has seen OSG as an organization excel at various levels, keeping up with our reputation of being customer - centric. OSG operates extensively in India providing innovative IT solutions, server, storage virtualization technologies, IT infrastructure management and its substance
                  </>
                }
              </div>
              <div>
                <button className="btn btn-know-more" onClick={() => setKnowMore(!knowMore)}>{knowMore ? 'Show Less' : "Know More"}</button>
              </div>
            </div>
            <div style={{ marginTop: "20px" }} className="row">
              <div
                className="col-lg-6 col-md-6 col-12 mt-1"
                data-aos="fade-right"
              >
                <div className="about_box_01 sm-h300" >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "46px", width: "100%" }}
                  >


                    <img src={Component_237_1} alt="icons" />
                    <div>
                      <div style={{ fontSize: "20px" }}>
                        <b>One Stop Solution</b>
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        We have everything for your IT Infrastructure needs
                        ranging from computer hardware to software and solutions
                      </div>
                    </div>

                    {/* ========================================= */}

                    {/* <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-md-4 col-lg-2 col-12 ">
                      <img src={Component_237_1} alt="icons" className="sm-center"/>
                      </div>
                      <div className="col-md-8 col-lg-10 col-12">
                      <div style={{ fontSize: "20px" }}>
                        <b className="sm-center">One Stop Solution</b>
                      </div>
                      <div style={{ fontSize: "16px" }} >
                        We have everything for your IT Infrastructure needs
                        ranging from computer hardware to software and solutions
                      </div>
                      </div>

                    </div> */}

                    {/* =========================================== */}

                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 mt-1"
                data-aos="fade-left"
              >
                <div className="about_box_01 sm-h250" >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "46px", width: "100%" }}
                  >
                    <img src={Component_238_1} alt="icons" />
                    <div>
                      <div style={{ fontSize: "20px" }}>
                        <b>Peace Of Mind</b>
                      </div>
                      <div style={{ fontSize: "16px" }}>Connect with us and leave your worries to us, We make sure that your satisfaction is always our priority
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 mt-1"
                data-aos="fade-right"
              >
                <div className="about_box_01 sm-h300">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "46px", width: "100%" }}
                  >
                    <img src={Component_239_1} alt="icons" />
                    <div>
                      <div style={{ fontSize: "20px" }}>
                        <b>Lightning Speed</b>
                      </div>
                      <div style={{ fontSize: "16px" }}>We are the market Leaders in terms of service delivery, We know the value of time & try to always save it
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 mt-1"
                data-aos="fade-left"
              >
                <div className="about_box_01 sm-h300">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "46px", width: "100%" }}
                  >
                    <img src={noun} alt="icons" />
                    <div>
                      <div style={{ fontSize: "20px" }}>
                        <b>Customer Centric</b>
                      </div>
                      <div style={{ fontSize: "16px" }}>In past 20+ Years of our journey, we have always placed our customers on top and delivered the best
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a
              style={{
                padding: "79px 0px 140px 0px",
                gap: "7px",
                cursor: "pointer",
              }}
              className="d-flex align-items-center"
              href={Techigent_2021}
              download
            >
              <FaDownload color="#C51213" className="mr-2" size='26px' />
              <div style={{ color: "#C51213", fontSize: "21px" }}>
                Download Company Profile
              </div>
            </a>
            <div className="about_effect">
              <im src={Component_225_1} alt="effect" />
            </div>
          </div>
        </div>

        <div className="productandservice sm-pt1">
          <div className="container " style={{ position: "relative" }}>
            <img
              src={Component_226_5}
              alt="logo"
              className="car_effect_03 sm-none"
            />

            <div style={{ color: "#111111", fontSize: "30px" }}>
              <b className="sm-25">Our Solutions</b>
            </div>
            <div style={{ color: "#555554", fontSize: "18px" }}>
              More then 150 Brands & Counting
            </div>
            <div style={{ paddingTop: "30px" }}>
              <div className="row" data-aos="fade-left">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="pas_box"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/cyberSecurity/network-perimeter')}>
                    <img
                      src={Cyber}
                      alt="error"
                      width="100%"
                      height="227px"
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                    />
                    <div
                      className="d-flex  justify-content-center align-items-center"
                      style={{ height: "100px", cursor: "pointer" }}
                    >
                      <div
                        style={{ fontSize: "18px", fontWeight: 'bold' }}
                        className="sm-20"
                      >
                        Cyber Security
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="pas_box"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/infrastructure/smart-data')}>
                    <img
                      src={infrastructure}
                      alt="error"
                      width="100%"
                      height="227px"
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                    />
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100px", cursor: "pointer" }}
                    >
                      <div
                        style={{ fontSize: "18px", fontWeight: 'bold' }}
                        className="sm-20"
                      >
                        Infrastructure Modernization
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="pas_box"
                    onClick={() => navigate('/product&service/software-solutions')}
                    style={{ cursor: 'pointer' }}>
                    <img
                      src={Softwares}
                      alt="error"
                      width="100%"
                      height="227px"
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                    />
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100px", cursor: "pointer" }}
                    >
                      <div
                        style={{ fontSize: "18px", fontWeight: 'bold' }}
                        className="sm-20"
                      >
                        Software Solutions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "30px" }} data-aos="fade-right">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="pas_box"
                    onClick={() => navigate('/product&service/endpoints')}
                    style={{ cursor: 'pointer' }}>
                    <img
                      src={Intelligent}
                      alt="error"
                      width="100%"
                      height="227px"
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                    />
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100px", cursor: "pointer" }}
                    >
                      <div
                        style={{ fontSize: "18px", fontWeight: 'bold' }}
                        className="sm-20"
                      >
                        Intelligent Devices
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="pas_box"
                    onClick={() => navigate('/product&service/mobility-solutions')}
                    style={{ cursor: 'pointer' }}>
                    <img
                      src={Mobility}
                      alt="error"
                      width="100%"
                      height="227px"
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                    />
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100px", cursor: "pointer" }}

                    >
                      <div
                        style={{

                          fontSize: "18px",
                          width: "228px",
                          textAlign: "center", fontWeight: 'bold'
                        }}
                        className="sm-20"
                      >
                        Mobility
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="pas_box"
                    onClick={() => navigate('/product&service/services-solutions')}
                    style={{ cursor: 'pointer' }}>
                    <img
                      src={inno}
                      alt="error"
                      width="100%"
                      height="227px"
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                    />
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100px", cursor: "pointer" }}
                    >
                      <div
                        style={{ fontSize: "18px", fontWeight: 'bold' }}
                        className="sm-20"
                      >
                        Services
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="awards " data-aos="fade-up">
          <div className="container" style={{ position: "relative" }}>
            <img
              src={Component_226_5}
              alt="logo"
              className="car_effect_03 sm-none"
            />
            <div className="d-flex align-items-center justify-content-between ">
              <div>
                <div style={{ color: "#111111" }} className="thirty">
                  <b className="sm-25">Awards & Recognitions</b>
                </div>
                <div
                  style={{ color: "#555554", fontSize: "18px" }}
                  className="sm-none"
                >
                  Our efforts were recognised globally
                </div>
              </div>
              <div>
                <Link
                  to="/awards&recogination"
                  style={{
                    fontSize: "15px",
                    color: "#C51213",
                    cursor: "pointer",
                  }}
                >
                  <u>EXPLORE</u>
                </Link>
              </div>
            </div>
            {awords === 1 &&
              <div
                style={{ paddingTop: "50px" }}
                className="d-flex justify-content-between sm-gap-10  sm-scroll "
              >
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2000} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2002} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2003} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>

              </div>
            }
            {awords === 2 &&
              <div
                style={{ paddingTop: "50px" }}
                className="d-flex justify-content-between sm-gap-10  sm-scroll "
              >
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2006} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2008} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2010} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>

              </div>
            }
            {awords === 3 &&
              <div
                style={{ paddingTop: "50px" }}
                className="d-flex justify-content-between sm-gap-10  sm-scroll "
              >
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2011} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2013} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>
                <div
                  className="comp_logo_1 d-flex align-items-center justify-content-center"
                  data-aos="flip-left"
                >
                  <img src={IMG2015} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
                </div>

              </div>
            }
            <div
              className="d-flex justify-content-center align-items-center sm-none"
              style={{ gap: "11px", marginTop: "80px" }}
            >
              <div className={awords === 1 ? 'dot-active' : 'dot-inactive'} onClick={() => setAwords(1)}></div>
              <div className={awords === 2 ? 'dot-active' : 'dot-inactive'} onClick={() => setAwords(2)}></div>
              <div className={awords === 3 ? 'dot-active' : 'dot-inactive'} onClick={() => setAwords(3)}></div>
            </div>
          </div>
        </div>


        <div className="it_01" data-aos="fade-up">
          <div className="container" style={{ position: "relative" }}>
            <img src={Component_226_5} alt="logo" className="car_effect_03 sm-none" />
            <div style={{ fontSize: "30px" }}>
              <b className="sm-25">Vision & Mission</b>
            </div>
            <div
              style={{ fontSize: "18px" }}
              className="mt-1"
            >
              Our efforts were recognised globally
            </div>
            <div style={{ paddingTop: "30px" }} className="row">
              <div className="col-lg-6 col-md-6 col-12 mt-2">
                <div className="vision_box d-flex align-items-center flex-column">
                  <div style={{ color: '#C51213', fontSize: "22px" }}>
                    <b>Our Vision</b>
                  </div>
                  <div
                    style={{
                      color: "#111111",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                    className="mt-3"
                  >
                    “To be the market leaders in technology products, services and solutions space by offering our own portfolio that could completely innovate this space.”
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 mt-2">
                <div className="vision_box d-flex align-items-center flex-column">
                  <div style={{ color: '#C51213', fontSize: "22px" }}>
                    <b>Our Mission</b>
                  </div>
                  <div
                    style={{
                      color: "#111111",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                    className="mt-3"
                  >
                    “To be an customer centric and people oriented organisation with continuous innovation and being future ready.”
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial sm-pt2" data-aos="fade-right" style={{ backgroundColor: 'white' }}>
          <div className="container" style={{ position: "relative" }}>
            <img
              src={Component_226_5}
              alt="logo"
              className="car_effect_03 sm-none"
            />
            <div className="thirty">
              <b className="sm-25">Why Choose Us!</b>
            </div>
            <div style={{ fontSize: "18px" }}>
              See what people are saying
            </div>
            <div style={{ paddingTop: "50px" }}>

              <div
                style={{ paddingTop: "50px" }}
                className="d-flex justify-content-between sm-gap-10 full-scroll "
              >
                <div
                  data-aos="flip-right"
                >
                  <div className="test_box_2">

                    <div
                      style={{ marginTop: "0px" }}
                      className="d-flex flex-column"
                    >
                      <div
                        style={{ fontSize: "16px" }}
                        className="mb-auto"
                      >
                        " Great Service and Support by Partner as well as Team,
                        Quick Service and Robust Customer support are thr keys of
                        success to Techigent. Kewep doing good "
                      </div>
                      <div className="d-flex justify-content-between mt-3 mr-4">
                        <img
                          src={user}
                          alt="error"
                          width="100px"
                          height="100px"
                          style={{ padding: '10px' }}

                          className="test_img"
                        />
                        <div className="mt-4 mr-4">

                          <div style={{ fontWeight: 'bold', fontSize: "16px" }}>
                            Natasha Singh
                          </div>
                          <div style={{ fontSize: "16px" }}>
                            Thakur International
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dot_des_1">
                      <img
                        src={Component_229_1}
                        alt="error"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="flip-right"
                >
                  <div className="test_box_2">

                    <div
                      style={{ marginTop: "0px" }}
                      className="d-flex flex-column"
                    >
                      <div
                        style={{ fontSize: "16px" }}
                        className="mb-auto"
                      >
                        " Great Service and Support by Partner as well as Team,
                        Quick Service and Robust Customer support are thr keys of
                        success to Techigent. Kewep doing good "
                      </div>
                      <div className="d-flex justify-content-between mt-3 mr-4">
                        <img
                          src={user}
                          alt="error"
                          width="100px"
                          height="100px"
                          style={{ padding: '10px' }}

                          className="test_img"
                        />
                        <div className="mt-4 mr-4">

                          <div style={{ fontWeight: 'bold', fontSize: "16px" }}>
                            Natasha Singh
                          </div>
                          <div style={{ fontSize: "16px" }}>
                            Thakur International
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dot_des_1">
                      <img
                        src={Component_229_1}
                        alt="error"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="flip-right"
                >
                  <div className="test_box_2">

                    <div
                      style={{ marginTop: "0px" }}
                      className="d-flex flex-column"
                    >
                      <div
                        style={{ fontSize: "16px" }}
                        className="mb-auto"
                      >
                        " Great Service and Support by Partner as well as Team,
                        Quick Service and Robust Customer support are thr keys of
                        success to Techigent. Kewep doing good "
                      </div>
                      <div className="d-flex justify-content-between mt-3 mr-4">
                        <img
                          src={user}
                          alt="error"
                          width="100px"
                          height="100px"
                          style={{ padding: '10px' }}

                          className="test_img"
                        />
                        <div className="mt-4 mr-4">

                          <div style={{ fontWeight: 'bold', fontSize: "16px" }}>
                            Natasha Singh
                          </div>
                          <div style={{ fontSize: "16px" }}>
                            Thakur International
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dot_des_1">
                      <img
                        src={Component_229_1}
                        alt="error"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="flip-right"
                >
                  <div className="test_box_2">

                    <div
                      style={{ marginTop: "0px" }}
                      className="d-flex flex-column"
                    >
                      <div
                        style={{ color: "#5D6186", fontSize: "16px" }}
                        className="mb-auto"
                      >
                        " Great Service and Support by Partner as well as Team,
                        Quick Service and Robust Customer support are thr keys of
                        success to Techigent. Kewep doing good "
                      </div>
                      <div className="d-flex justify-content-between mt-3 mr-4">
                        <img
                          src={user}
                          alt="error"
                          width="100px"
                          height="100px"
                          style={{ padding: '10px' }}

                          className="test_img"
                        />
                        <div className="mt-4 mr-4">

                          <div style={{ fontWeight: 'bold', fontSize: "16px" }}>
                            Natasha Singh
                          </div>
                          <div style={{ fontSize: "16px" }}>
                            Thakur International
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="dot_des_1">
                      <img
                        src={Component_229_1}
                        alt="error"
                        width="40px"
                        height="40px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_up">
          <div className="sm-none">
            <Footer />
          </div>
          <div className="lg-none">
            <FooterMob />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
