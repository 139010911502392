import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import IMG2000 from "../../Images/aword/IMG_2000.JPG";
import IMG2002 from "../../Images/aword/IMG_2002.JPG";
import IMG2003 from "../../Images/aword/IMG_2003.JPG";
import IMG2006 from "../../Images/aword/IMG_2006.JPG";
import IMG2008 from "../../Images/aword/IMG_2008.JPG";
import IMG2010 from "../../Images/aword/IMG_2010.JPG";
import IMG2011 from "../../Images/aword/IMG_2011.JPG";
import IMG2013 from "../../Images/aword/IMG_2013.JPG";
import IMG2015 from "../../Images/aword/IMG_2015.JPG";
import IMG2018 from "../../Images/aword/IMG_2018.JPG";
import IMG2020 from "../../Images/aword/IMG_2020.JPG";
import IMG2021 from "../../Images/aword/IMG_2021.JPG";
import IMG2026 from "../../Images/aword/IMG_2026.JPG";
import IMG2027 from "../../Images/aword/IMG_2027.JPG";
import IMG2028 from "../../Images/aword/IMG_2028.JPG";
import IMG2030 from "../../Images/aword/IMG_2030.JPG";
import IMG2031 from "../../Images/aword/IMG_2031.JPG";
import IMG2032 from "../../Images/aword/IMG_2032.JPG";
import IMG2033 from "../../Images/aword/IMG_2033.JPG";
import IMG2034 from "../../Images/aword/IMG_2034.JPG";
// Dummy Images

const AwardComp = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);
    return (
        <>
            <div className="awards_box sm-pt3" data-aos="fade-up">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2000} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2002} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2033} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2003} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2006} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2008} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2010} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2011} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2013} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2015} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2018} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2020} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2021} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2032} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2026} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2027} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2028} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2030} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2031} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2034} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AwardComp