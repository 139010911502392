import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import Footer from './Footer'
import MainHeader from './MainHeader'
import FooterMob from '../Layout/FooterMob'
import person1 from '../../Images/person/anil.jpg'
import sagar from '../../Images/person/awa.jpg'
import awa from '../../Images/person/awa1.jpg'
import sunil from '../../Images/person/sunil.jpg'
import person3 from '../../Images/person/mukesh.jpg'
import person4 from '../../Images/person/IMG_0559.jpeg'
import Component_226_5 from '../../Images/Component_226_5.svg'


// Icons
import { FaChevronRight } from 'react-icons/fa'
import { useState } from 'react'

// Dummy Images

// Dummy array 

const LeaderTeam = () => {
    const [id, setId] = useState(0)
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 130 });
    }, []);

    return (
        <>
            <div>
                <MainHeader img="c_img_team sm-carrer-img" name="Leadership Team" title="People who made us what we are" />
                <div className="container">
                    <div style={{ padding: "120px 0px" }} className="sm-pt3">
                        <div className="row d-flex justify-content-around">
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 1 ? "leader_box_1" : "leader_box"} onClick={() => setId(1)}>
                                        <img src={person1} alt="leaders" width='320px' height='450px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 1 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Anil Tiwari</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={id === 1 ? { color: '#fff', fontSize: "15px" } : { color: '#C51213', fontSize: "15px" }} >Managing Director</div>
                                                {id !== 1 &&
                                                    <FaChevronRight style={{ color: "#C51213" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 1 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 lg-none">
                                {id === 1 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ fontSize: "30px" }}><b className='sm-25'>Anil Tiwari</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>He is an effective business leader and an aspiration for young startups. As successful business Leader achieved many milestones. He proved his true quality of leadership and determination. Currently owning a group of Companies having worth of 500+ cr with the support of 200 + expertise as well as having a well - known reputed brands of 50 + IT Product and Services.
                                                    <br /><br />
                                                    With the belief that co-operation can create wonders, he made this journey more effective with his solid pillars and we are confident enough that under his leadership we will achieve our vision of 1000 crores by 2025. In the coming years, we expect to expand ourselves globally and extend our IT Solutions far beyond imagination.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 2 ? "leader_box_1" : "leader_box"} onClick={() => setId(2)}>
                                        <img src={person4} alt="leaders" width='320px' height='450px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 2 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Praveen Tiwari</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={id === 2 ? { color: '#fff', fontSize: "15px" } : { color: '#C51213', fontSize: "15px" }} >Director Sales </div>
                                                {id !== 2 &&
                                                    <FaChevronRight style={{ color: "#C51213" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 2 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 sm-none">
                                {id === 1 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ fontSize: "30px" }}><b className='sm-25'>Anil Tiwari</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>He is an effective business leader and an aspiration for young startups. As successful business Leader achieved many milestones. He proved his true quality of leadership and determination. Currently owning a group of Companies having worth of 500+ cr with the support of 200 + expertise as well as having a well - known reputed brands of 50 + IT Product and Services.
                                                    <br /><br />
                                                    With the belief that co-operation can create wonders, he made this journey more effective with his solid pillars and we are confident enough that under his leadership we will achieve our vision of 1000 crores by 2025. In the coming years, we expect to expand ourselves globally and extend our IT Solutions far beyond imagination.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-12">
                                {id === 2 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '20px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ fontSize: "30px" }}><b className='sm-25'>Mr. Praveen Tiwari</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>He is Bsc. ( Physics), having 15 years of experience in IT industry and solution services. He has an expertise in product technical designing. He has been associated with the company since its incorporation and have made his contribution to the success of this organisation. He is a vibrant leader and have handled multiple verticals of our business.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 3 ? "leader_box_1" : "leader_box"} onClick={() => setId(3)}>
                                        <img src={person3} alt="leaders" width='320px' height='450px' className="leader_img" />
                                        <div className="leader_box_2">
                                            <div style={id === 3 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Mukesh Sinha </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={id === 3 ? { color: '#fff', fontSize: "15px" } : { color: '#C51213', fontSize: "15px" }} >Assoiciate director / Enterprise account</div>
                                                {id !== 3 &&
                                                    <FaChevronRight style={{ color: "#C51213" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 3 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 lg-none">
                                {id === 3 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ fontSize: "30px" }}><b className='sm-25'>Mr. Mukesh Sinha</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>With 20+ years of IT industry experience he has an expertise in Sales & Marketing. He is an Msc. in Physics. He has has been associated with the organization since its inception.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"}>
                                        <img src={sagar} alt="leaders" width='320px' height='450px' className="leader_img" />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Sagar Swargam</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={id === 4 ? { color: '#fff', fontSize: "15px" } : { color: '#C51213', fontSize: "15px" }} >Head Education vertical</div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#C51213" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 sm-none">
                                {id === 3 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ fontSize: "30px" }}><b className='sm-25'>Mukesh Sinha</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>With 20+ years of IT industry experience he has an expertise in Sales & Marketing. He is an Msc. in Physics. He has has been associated with the organization since its inception.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 5 ? "leader_box_1" : "leader_box"} >
                                        <img src={awa} alt="leaders" width='320px' height='450px' className="leader_img" />
                                        <div className="leader_box_2">
                                            <div style={id === 5 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr Awadesh Pandey</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={id === 5 ? { color: '#fff', fontSize: "15px" } : { color: '#C51213', fontSize: "15px" }} >Head Services Support /Out of warranty Business</div>
                                                {id !== 5 &&
                                                    <FaChevronRight style={{ color: "#C51213" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 5 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 6 ? "leader_box_1" : "leader_box"}>
                                        <img src={sunil} alt="leaders" width='320px' height='450px' className="leader_img" />
                                        <div className="leader_box_2">
                                            <div style={id === 6 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Sunil Gurav </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={id === 6 ? { color: '#fff', fontSize: "15px" } : { color: '#C51213', fontSize: "15px" }} >Head purchase</div>
                                                {id !== 6 &&
                                                    <FaChevronRight style={{ color: "#C51213" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 6 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaderTeam