import React from 'react'
import Footer from './Footer'
import FooterMob from './FooterMob'
import event from "../../Images/transvation.jpeg";

const Transvation = () => {
    return (
        <>
            <div
                className="event_box " style={{ paddingTop: '130px', paddingBottom: '30px' }}
            >
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12" style={{ padding: "0px" }}>
                        <img className="event_img" src={event} alt="event_banner" width="100%" height="361px" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12" style={{ padding: "0px" }}>
                        <div style={{ padding: "45px 101px" }}>
                            <div
                                style={{
                                    fontSize: "12px",

                                    marginTop: "28px",
                                }}
                            >
                                <b>ANNUAL EVENT</b>
                            </div>
                            <div
                                style={{
                                    marginTop: "21px",
                                    fontSize: "14px",
                                    color: "#999999",
                                }}
                            >
                                JAN 13, 2023
                            </div>
                            <div style={{ color: "#111111", fontSize: "24px" }}>
                                TRANSVATION <br />
                                The event begins now!
                            </div>
                            <div className="mt-4">
                                <a className="btn btn-register" href="https://youtu.be/KojqoBHeSbg" rel="noreferrer" target="_blank">Go Live</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_up">
                <div className="sm-none">
                    <Footer />
                </div>
                <div className="lg-none">
                    <FooterMob />
                </div>
            </div>
        </>
    )
}

export default Transvation