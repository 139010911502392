import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import Footer from '../Layout/Footer'

// Images
import FooterMob from '../Layout/FooterMob'
import axios from 'axios'
import { MdCall, MdMail } from 'react-icons/md'
import { FaWhatsapp } from 'react-icons/fa'

const ContactusDetails = () => {
    const [count, setCount] = useState(0)
    const [status, setStatus] = useState(false)
    const navigate = useNavigate();
    const admin = localStorage.getItem('admin')
    console.log(admin)
    useEffect(() => {
        if (admin === '') {
            navigate('/login')
        }
        Aos.init({ duration: 1000, delay: 90 });
        getData()
    }, []);

    const [data, setData] = useState([])
    const getData = () => {
        axios.get('https://api-techigent.vercel.app/contactus/all')
            .then((response) => {
                setData(response.data.filter((list) => list.companyStatus === 2))
            })
            .catch((error) => {
                alert(error.message)
            })
    }
    const updateVacancy = (id, no) => {
        axios.post(`https://api-techigent.vercel.app/contactus/update/${id}`, {
            noofvacancy: no,
        })
            .then((response) => {
                console.log(response);
                getData()
            }, (error) => {
                console.log(error);
            });
    }
    const updateStatus = (id, no) => {
        axios.post(`https://api-techigent.vercel.app/contactus/update/${id}`, {
            Status: no,
        })
            .then((response) => {
                console.log(response);
                getData()
            }, (error) => {
                console.log(error);
            });
    }
    const vacancyDelete = (id) => {
        axios.delete(`https://api-techigent.vercel.app/contactus/delete/${id}`)
            .then((response) => {
                console.log(response);
                getData()
            }, (error) => {
                console.log(error);
            });
    }
    return (
        <>
            <div style={{ marginTop: '100px' }}>
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-around sm-scroll sm-14 sm-gap-10">
                            <div style={{ color: "#C51213", borderBottom: "2px solid #C51213", paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/contactDetails')}><b>Requested ContactUs</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/candidate')}><b>Candidate</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/dashboard')}><b>Added Vacancy</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/add-vacancy')}><b>Add New Vacancy</b></div>
                        </div>
                    </div>


                </div>
                <div className="row sm-pt4 full-scroll ml-4 mr-4" style={{ padding: "20px 0px 20px 0px" }} data-aos="fade-right">
                    <table style={{ width: '100%', padding: '5px' }}>
                        <tr style={{ border: '2px solid #C51213', fontSize: '16px', height: '30px', alignItems: 'center', backgroundColor: '#C51213', color: 'white' }}>
                            <th>Sr.No</th>
                            <th>Name</th>
                            <th>Reason</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #C51213' }}>{index + 1}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #C51213' }}>{item.Name}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #C51213' }}>{item.subject}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #C51213' }}>{item.email}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #C51213' }}>{item.mobile}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #C51213' }}>{item.message}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #C51213' }}>
                                        <div className='d-flex justify-content-around'>
                                            <a className='mt-2' href={`tel:+91${item.mobile}`} target='_black'><MdCall style={{ cursor: 'pointer', color: 'blue', markerStart: '10px' }} size='24px' /></a>
                                            <a className='mt-2' href={`https://wa.me/${item.mobile}`} target='_black'><FaWhatsapp style={{ cursor: 'pointer', color: 'green', markerStart: '10px' }} size='24px' /></a>
                                            <a className='mt-2' href={`mailto: ${item.email}`} target='_black'> <MdMail style={{ cursor: 'pointer', color: 'orange', markerStart: '10px' }} size='24px' /></a>
                                            <button className="btn btn-delete mt-1 mb-1 mr-1 ml-1" data-aos="flip-right" onClick={() => vacancyDelete(item._id)}>
                                                <div className="d-flex justify-content-center aling-items-center">
                                                    <div>Delete</div>
                                                </div>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactusDetails