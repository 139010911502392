import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Components
import Footer from "./Footer";
import FooterMob from "./FooterMob";

// Images

import Group_54508 from "../../Images/Group_54508.svg";
import Path_115542 from "../../Images/Path_115542.svg";
import Group_74104 from "../../Images/Group_74104.svg";
import Group_74103 from "../../Images/Group_74103.svg";
import Component_227_3 from "../../Images/Component_227_3.svg";
import Component_234_1 from "../../Images/Component_234_1.svg";
import Component_229_1 from "../../Images/Component_229_1.svg";
import Component_226_5 from "../../Images/Component_226_5.svg";
import Component_225_1 from "../../Images/Group_74145.svg";
import IMG2000 from "../../Images/aword/IMG_2000.JPG";
import IMG2002 from "../../Images/aword/IMG_2002.JPG";
import IMG2003 from "../../Images/aword/IMG_2003.JPG";
import IMG2006 from "../../Images/aword/IMG_2006.JPG";
import IMG2008 from "../../Images/aword/IMG_2008.JPG";
import IMG2010 from "../../Images/aword/IMG_2010.JPG";
import IMG2011 from "../../Images/aword/IMG_2011.JPG";
import IMG2013 from "../../Images/aword/IMG_2013.JPG";
import IMG2015 from "../../Images/aword/IMG_2015.JPG";
import event from "../../Images/transvation.jpeg";
import emailjs from '@emailjs/browser';
import welcomeVideo from '../../Images/solutionsImg/welcomeTechigent.mp4'

// Icons
import { IoIosMail } from "react-icons/io";
import { FaLinkedin, FaTelegramPlane } from "react-icons/fa";
import { useSpring, animated } from "react-spring";
import AwardSolution from "./AwardSolution";
import axios from "axios";

// Dummy Images
// const dummy_img = "https://images.unsplash.com/photo-1542744095-291d1f67b221?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGxhcHRvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
const user =
  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 2000,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const Home = () => {
  const [error, setError] = useState(null)
  const navigate = useNavigate();
  const [contactUs, setContactUs] = useState({
    name: "",
    email: "",
    phone: "",
    reason: "",
    message: "",
  })
  const [awords, setAwords] = useState(1)
  const [emailId, setEmailId] = useState()

  useEffect(() => {
    Aos.init({ duration: 1000, delay: 90 });
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_crpvcv3', 'template_hv77n75', e.target, '-sGL2I2npRnAb-4oq')
      .then((result) => {
        console.log(result.text);
        alert('Successfully sended')
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  const contactCreate = () => {
    axios.post('https://api-techigent.vercel.app/contactus/create', {
      Name: contactUs.name,
      subject: contactUs.reason,
      email: contactUs.email,
      mobile: contactUs.phone,
      message: contactUs.message,
      Status: '1',
      companyStatus: 2
    })
      .then((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      });
  }
  const contactSubmit = (e) => {
    e.preventDefault();
    if (contactUs.name !== "" && contactUs.email !== "" && contactUs.phone !== "" && contactUs.reason !== "" && contactUs.message !== "") {
      contactCreate()
      emailjs.send("service_crpvcv3", "template_hv77n75", {
        from_name: contactUs.name,
        to_company: 'Om Sai Group',
        from_number: contactUs.phone,
        from_email: contactUs.email,
        from_reason: contactUs.reason,
        message: contactUs.message,
        reply_to: "Thank You for contacting us.",
      }, "-sGL2I2npRnAb-4oq").then((result) => {
        alert('Successfully sended')
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    } else {
      setError('Fill the all details');
    }
    e.target.reset();
    setContactUs({
      name: '',
      phone: '',
      email: '',
      reason: '',
      message: ''
    })

  };

  return (
    <>
      <div id="demo" className="carousel slide" data-ride="carousel">
        <ul className="carousel-indicators">
          <li data-target="#demo" data-slide-to="0" className="active"></li>
          <li data-target="#demo" data-slide-to="1"></li>
          <li data-target="#demo" data-slide-to="2"></li>
        </ul>
        <div className="carousel-inner video-container">
          <video autoplay='true' muted loop>
            <source src={welcomeVideo} type="video/mp4" />
          </video>
          <div className="redFrame"></div>
          <div className="carousel-item active">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "100%",
                color: "#fff",
                height: "100%",
                textAlign: "center",
                position: "relative",
              }}
            >
              <div className="container">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: "100%",
                    color: "#fff",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div style={{ width: "544px" }}>
                    <div style={{ fontSize: "36px" }} className="sm-video">
                      <b>Asia's Fastest Growing Technology Giant</b>
                    </div>
                    <div style={{ fontSize: "18px" }} className="mt-4 sm-14">
                      With robust customer support and quick service delivery,
                      OSG has topped the chart once again
                    </div>{/* 
                      <button
                        className="btn btn-km-1 mt-5"
                        onClick={() => navigate("/about")}
                      >
                        Know more
                      </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "100%",
                color: "#fff",
                height: "100%",
                textAlign: "center",
                position: "relative",
              }}
            >
              <div className="container">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: "100%",
                    color: "#fff",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div style={{ width: "544px" }}>
                    <div style={{ fontSize: "36px" }} className="sm-video">
                      <b>One Stop Solution for all your IT needs</b>
                    </div>
                    <div style={{ fontSize: "18px" }} className="mt-4 sm-14">
                      With robust customer support and quick service delivery,
                      OSG has topped the chart once again
                    </div>{/* 
                      <button
                        className="btn btn-km-1 mt-5 "
                        onClick={() => navigate("/about")}
                      >
                        Know more
                      </button> */}
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="carousel-item">

            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "100%",
                color: "#fff",
                height: "100%",
                textAlign: "center",
                position: "relative",
              }}
            >
              <div className="container">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: "100%",
                    color: "#fff",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div style={{ width: "544px" }}>
                    <div style={{ fontSize: "36px" }} className="sm-video">
                      <b>Robust Customer Support & Service Delivery</b>
                    </div>
                    <div style={{ fontSize: "18px" }} className="mt-4 sm-14">
                      With robust customer support and quick service delivery,
                      OSG has topped the chart once again
                    </div>{/* 
                      <button
                        className="btn btn-km-1 mt-5"
                        onClick={() => navigate("/about")}
                      >
                        Know more
                      </button> */}
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div>
            <img
              src={Component_227_3}
              alt="error"
              className="car_effect"
            />
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        className="home_c1_bg sm-pt2"
        style={{ position: "relative" }}
      >
        <div className="container" style={{ position: "relative" }}>
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />

          <div className="art_01 sm-none">
            <img
              src={Component_225_1}
              alt="error"
              width="346px"
              height="451px"
            />
          </div>
          <div style={{ fontSize: "30px" }} className="">
            <b className="sm-25">About Us</b>
          </div>
          <div
            style={{
              color: "#555554",
              fontSize: "18px",
              padding: "0px 0px 50px 0px",
            }}
            className="sm-pb2"
          >
            Growing since 2000
          </div>
          <div
            style={{ color: "#111111", fontSize: "16px", width: "1100px" }}
            className="sm-width"
            data-aos="fade-up"
          > Om Sai Group has over 20 years of experience in the IT Industry and its headquarters in Mumbai with PAN India presence in sales and services.<br />
            With a proven portfolio in IT solution, products and services, OSG has been delivering IT infrastructure - Products, Service & Solutions to a wide range of industry verticals including- Banking & Finance Services, Retail Distribution, Pharmaceuticals, Utilities and Real Estate. Its ready to deploy product portfolio comprises of turnkey IT solutions that help new age businesses leverage varied set of business data & information.
          </div>

          <div
            className="d-flex justify-content-between sm-scroll sm-gap-40"
            data-aos="fade-up"
          >
            <div style={{ marginTop: "35px" }}>
              <div
                style={{ color: "#C51213", fontSize: "30px" }}
                className="sm-20"
              >
                <b><Number n={20} /></b>
              </div>
              <div
                style={{ color: "#555554", fontSize: "18px" }}
                className="mt-2 sm-14"
              >
                Years of Experience
              </div>
            </div>
            <div style={{ marginTop: "35px" }}>
              <div
                style={{ color: "#C51213", fontSize: "30px" }}
                className="sm-20"
              >
                <b><Number n={2000} /></b>
              </div>
              <div
                style={{ color: "#555554", fontSize: "18px" }}
                className="mt-2 sm-14"
              >
                Happy Customers
              </div>
            </div>
            <div style={{ marginTop: "35px" }}>
              <div
                style={{ color: "#C51213", fontSize: "30px" }}
                className="sm-20"
              >
                <b><Number n={1000} /></b>
              </div>
              <div
                style={{ color: "#555554", fontSize: "18px" }}
                className="mt-2 sm-14"
              >
                Crores INR Turnover
              </div>
            </div>
            <div style={{ marginTop: "35px" }}>
              <div
                style={{ color: "#C51213", fontSize: "30px" }}
                className="sm-20"
              >
                <b><Number n={750} /></b>
              </div>
              <div
                style={{ color: "#555554", fontSize: "18px" }}
                className="mt-2 sm-14"
              >
                Support Locations
              </div>
            </div>
            <div style={{ marginTop: "35px" }}>
              <div
                style={{ color: "#C51213", fontSize: "30px" }}
                className="sm-20"
              >
                <b><Number n={25} /></b>
              </div>
              <div
                style={{ color: "#555554", fontSize: "18px" }}
                className="mt-2 sm-14"
              >
                Offices
              </div>
            </div>
            <div style={{ marginTop: "35px" }}>
              <div
                style={{ color: "#C51213", fontSize: "30px" }}
                className="sm-20"
              >
                <b><Number n={800} /></b>
              </div>
              <div
                style={{ color: "#555554", fontSize: "18px" }}
                className="mt-2 sm-14"
              >
                Employees
              </div>
            </div>
          </div>
          <div>
            <button
              className="btn btn-know-more"
              onClick={() => navigate("/about")}
              data-aos="fade-right"
            >
              Know More
            </button>
          </div>
        </div>
        {/* <div>
                    <img src={Component_227_3} alt="error" height="285px" className="car_effect_1" />
                </div> */}
      </div>

      <AwardSolution />

      <div
        className="event_box"
        data-aos="fade-zoom-in"
        data-aos-offset="250"
        data-aos-easing="ease-in-sine"
      >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12" style={{ padding: "0px" }}>
            <img className="event_img" src={event} alt="event_banner" width="100%" height="361px" />
          </div>
          <div className="col-lg-6 col-md-6 col-12" style={{ padding: "0px" }}>
            <div style={{ padding: "45px 101px" }}>
              <div
                style={{
                  fontSize: "12px",

                  marginTop: "28px",
                }}
              >
                <b>ANNUAL EVENT</b>
              </div>
              <div
                style={{
                  marginTop: "21px",
                  fontSize: "14px",
                  color: "#999999",
                }}
              >
                JAN 13, 2023
              </div>
              <div style={{ color: "#111111", fontSize: "24px" }}>
                TRANSVATION <br />
                The event begins now!
              </div>
              <div className="mt-4">
                <a className="btn btn-register" href="https://youtu.be/KojqoBHeSbg" rel="noreferrer" target="_blank">Go Live</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="caseStudy " data-aos="fade-up">
        {/* <div
          className="container"
          style={{ paddingBottom: "100px", position: "relative" }}
        >
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />
          <div
            className="d-flex align-items-center justify-content-between"
            data-aos="fade-right"
          >
            <div>
              <div style={{ color: "#111111" }} className="thirty">
                <b className="sm-25">Case Studies</b>
              </div>
              <div style={{ color: "#555554", fontSize: "18px" }}>
                We Delivered what we comitted
              </div>
            </div>
            <div>
              <Link
                to="/"
                style={{
                  fontSize: "15px",
                  color: "#0883E6",
                  cursor: "pointer",
                }}
              >
                <u>EXPLORE</u>
              </Link>
            </div>
          </div>

          <div
            style={{ padding: "50px 0px", gap: "28px" }}
            className="d-flex flex-column sm-none"
            data-aos="fade-left"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div style={{ color: "#000000", fontSize: "24px" }}>
                Case Study by Verticals
              </div>
              <div className="cs_02">All</div>
              <div className="cs_03">NBFC</div>
              <div className="cs_03">Health Care</div>
              <div className="cs_03">Manufacturing</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="cs_03">Retail</div>
              <div className="cs_03">Pharmaceuticals</div>
              <div className="cs_03">Automotive</div>
              <div className="cs_03">Education</div>
              <div className="cs_03">Others</div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center sm-scroll srolling_effect sm-gap-40 mt-2 sm-mt2 ">
            <div className="cs_01 " data-aos="flip-right">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  Capital Confidential: ‘Big Short’ author contemplates taking
                  on Brexit
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 29, 2019 at 6:06 a.m. ET
                </div>
              </div>
            </div>

            <div className="cs_01" data-aos="flip-right">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  U.K. assets are too hot to handle right now, top investors say
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 29, 2019 at 6:06 a.m. ET
                </div>
              </div>
            </div>
            <div className="cs_01" data-aos="flip-right">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  Europe’s largest money manager warns of an investment industry
                  liquidity crisis
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 25, 2019 at 4:17 a.m. ET
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center mt-5 sm-none"
            style={{ gap: "11px" }}
          >
            <div className="dot-active"></div>
            <div className="dot-inactive"></div>
            <div className="dot-inactive"></div>
          </div>
        </div> */}

      </div>
      <div className="awards " data-aos="fade-up">
        <div className="container" style={{ position: "relative" }}>
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />
          <div className="d-flex align-items-center justify-content-between ">
            <div>
              <div style={{ color: "#111111" }} className="thirty">
                <b className="sm-25">Awards & Recognitions</b>
              </div>
              <div
                style={{ color: "#555554", fontSize: "18px" }}
                className="sm-none"
              >
                Our efforts were recognised globally
              </div>
            </div>
            <div>
              <Link
                to="/awards&recogination"
                style={{
                  fontSize: "15px",
                  color: "#C51213",
                  cursor: "pointer",
                }}
              >
                <u>EXPLORE</u>
              </Link>
            </div>
          </div>
          {awords === 1 &&
            <div
              style={{ marginTop: "50px" }}
              className="d-flex justify-content-between sm-gap-10  sm-scroll "
            >
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2000} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2002} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2003} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>

            </div>
          }
          {awords === 2 &&
            <div
              style={{ marginTop: "50px" }}
              className="d-flex justify-content-between sm-gap-10  sm-scroll "
            >
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2006} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2008} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2010} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>

            </div>
          }
          {awords === 3 &&
            <div
              style={{ marginTop: "50px" }}
              className="d-flex justify-content-between sm-gap-10  sm-scroll "
            >
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2011} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2013} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>
              <div
                className="comp_logo_1 d-flex align-items-center justify-content-center"
                data-aos="flip-left"
              >
                <img src={IMG2015} alt="error" width="350px" height="350px" style={{ borderRadius: '10px' }} />
              </div>

            </div>
          }
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "11px", marginTop: "40px", marginBottom: '40px' }}
          >
            <div className={awords === 1 ? 'dot-active' : 'dot-inactive'} onClick={() => setAwords(1)}></div>
            <div className={awords === 2 ? 'dot-active' : 'dot-inactive'} onClick={() => setAwords(2)}></div>
            <div className={awords === 3 ? 'dot-active' : 'dot-inactive'} onClick={() => setAwords(3)}></div>
          </div>
        </div>
      </div>
      {/* <div className="blog_post sm-pt2" data-aos="fade-up">
        <div className="container" style={{ position: "relative" }}>
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ paddingTop: "10px" }}
          >
            <div style={{ color: "#00155A" }} className="thirty">
              <b className="sm-25">Blogs & Posts</b>
            </div>
            <div style={{ color: "#0883E6" }} className="fifteen">
              <u>EXPLORE</u>
            </div>
          </div>

          <div
            className="d-flex justify-content-between srolling_effect sm-scroll sm-gap-10"
            style={{ marginTop: "50px" }}
          >
            <div className="cs_01" data-aos="flip-right">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  Capital Confidential: ‘Big Short’ author contemplates taking
                  on Brexit
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 29, 2019 at 6:06 a.m. ET
                </div>
              </div>
            </div>

            <div className="cs_01" data-aos="flip-right">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  U.K. assets are too hot to handle right now, top investors say
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 29, 2019 at 6:06 a.m. ET
                </div>
              </div>
            </div>
            <div className="cs_01" data-aos="flip-right">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  Europe’s largest money manager warns of an investment industry
                  liquidity crisis
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 25, 2019 at 4:17 a.m. ET
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center sm-none"
            style={{ gap: "11px", marginTop: "80px" }}
          >
            <div className="dot-active"></div>
            <div className="dot-inactive"></div>
            <div className="dot-inactive"></div>
            
          </div>
        </div>
      </div> */}

      <div className="testimonial sm-pt2" data-aos="fade-right" style={{ backgroundColor: 'white' }}>
        <div className="container" style={{ position: "relative" }}>
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />
          <div className="thirty">
            <b className="sm-25">Our Values!</b>
          </div>
          <div style={{ fontSize: "18px" }}>
            An organisation with compassion
          </div>
          <div >

            <div
              style={{ paddingTop: "20px" }}
              className="d-flex justify-content-between sm-gap-10 full-scroll "
            >
              <div
                data-aos="flip-right"
              >
                <div className="test_box_2">

                  <div
                    style={{ marginTop: "0px" }}
                    className="d-flex flex-column"
                  >

                    <div className="mt-2 mr-4 mb-2">

                      <div style={{ fontWeight: 'bold', fontSize: "18px" }}>
                        Transparency
                      </div>
                    </div>
                    <div
                      style={{ fontSize: "16px", paddingBottom: '40px' }}
                      className="mb-auto "
                    >
                      " Transparent reporting gives our Clients a clear summary of our activities, making budgeting and vision simple and concise"
                    </div>
                  </div>
                  <div className="dot_des_1">
                    <img
                      src={Component_229_1}
                      alt="error"
                      width="40px"
                      height="40px"
                    />
                  </div>
                </div>
              </div>
              <div
                data-aos="flip-right"
              >
                <div className="test_box_2">
                  <div
                    style={{ marginTop: "0px" }}
                    className="d-flex flex-column"
                  >

                    <div className="mt-2 mr-4 mb-2">

                      <div style={{ fontWeight: 'bold', fontSize: "18px" }}>
                        Human Values
                      </div>
                    </div>
                    <div
                      style={{ fontSize: "16px", paddingBottom: '40px' }}
                      className="mb-auto "
                    >
                      " We have confidence in having an impact on individuals. We have faith in bringing "Human" to our offering "
                    </div>
                  </div>
                  <div className="dot_des_1">
                    <img
                      src={Component_229_1}
                      alt="error"
                      width="40px"
                      height="40px"
                    />
                  </div>
                </div>
              </div>
              <div
                data-aos="flip-right"
              >
                <div className="test_box_2">

                  <div
                    style={{ marginTop: "0px" }}
                    className="d-flex flex-column"
                  >

                    <div className="mt-2 mr-4 mb-2">

                      <div style={{ fontWeight: 'bold', fontSize: "18px" }}>
                        Integrity
                      </div>
                    </div>
                    <div
                      style={{ fontSize: "16px" }}
                      className="mb-auto"
                    >
                      " Acting and making decisions in a way that is fair and legit. Integrity for us implies budgetary and scholarly,
                      along with every other structures which are commonly comprehended"
                    </div>
                  </div>
                  <div className="dot_des_1">
                    <img
                      src={Component_229_1}
                      alt="error"
                      width="40px"
                      height="40px"
                    />
                  </div>
                </div>
              </div>
              <div
                data-aos="flip-right"
              >
                <div className="test_box_2">

                  <div
                    style={{ marginTop: "0px" }}
                    className="d-flex flex-column"
                  >

                    <div className="mt-2 mr-4 mb-2">

                      <div style={{ fontWeight: 'bold', fontSize: "18px" }}>
                        Speed
                      </div>
                    </div>
                    <div
                      style={{ fontSize: "16px" }}
                      className="mb-auto"
                    >
                      " Responding to all the customers with a sense of urgency. Continuously endeavouring to finish before the deadlines
                      and choosing the best rhythm to optimize organisational efficiencies. "
                    </div>
                  </div>
                  <div className="dot_des_1">
                    <img
                      src={Component_229_1}
                      alt="error"
                      width="40px"
                      height="40px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="blog_post sm-pt2">
        <div className="container" style={{ position: "relative" }}>
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />
          <div
            className="d-flex align-items-center justify-content-between sm-pt1"
            style={{ paddingTop: "10px" }}
            data-aos="fade-left"
          >
            <div style={{ color: "#00155A" }} className="thirty">
              <b className="sm-25">News & Events</b>
            </div>
            <div style={{ color: "#0883E6" }} className="fifteen">
              <u>EXPLORE</u>
            </div>
          </div>
          <div
            className="d-flex justify-content-between srolling_effect sm-gap-20"
            style={{ marginTop: "50px" }}
          >
            <div className="cs_01" data-aos="flip-left">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  Capital Confidential: ‘Big Short’ author contemplates taking
                  on Brexit
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 29, 2019 at 6:06 a.m. ET
                </div>
              </div>
            </div>

            <div className="cs_01" data-aos="flip-left">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  U.K. assets are too hot to handle right now, top investors say
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 29, 2019 at 6:06 a.m. ET
                </div>
              </div>
            </div>
            <div className="cs_01" data-aos="flip-left">
              <img
                src={case_study}
                alt="ERROR"
                className="case_img"
                width="100%"
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{ padding: "15px 10px", height: "137px" }}
              >
                <div
                  style={{ color: "#111111", fontSize: "18px" }}
                  className="mb-auto"
                >
                  Europe’s largest money manager warns of an investment industry
                  liquidity crisis
                </div>
                <div
                  style={{ color: "#555554", fontSize: "15px", width: "320px" }}
                  className="mt-1"
                >
                  Oct. 25, 2019 at 4:17 a.m. ET
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center sm-none"
            style={{ gap: "11px", marginTop: "80px" }}
          >
            <div className="dot-active"></div>
            <div className="dot-inactive"></div>
            <div className="dot-inactive"></div>
          </div>
        </div>
      </div> */}

      <div
        className="container sm-pt1"
        style={{ padding: "10px 10px 20px 10px" }}
        data-aos="fade-right"
      >
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12">
            <div style={{ fontSize: "21px", color: "#111111" }}>
              <b>Stay Updated!</b>
            </div>
            <div
              style={{ fontSize: "15px", width: "100%", color: "#111111" }}
              className="mt-3"
            >
              Subscribe to our Newsletter and stay updated on Industry Trends,
              New Products & Services and much more
            </div>

            <form onSubmit={sendEmail}>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "20px" }}
              >
                <input
                  type="email"
                  className="mail_input"
                  name="from_name"
                  placeholder="Enter Your E-mail Here"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
                <input
                  type="hidden"
                  className="mail_input"
                  name="reply_to"
                  value='Thank you contacting us'
                />
                <button className="mail_btn btn d-flex align-items-center justify-content-center" type="submit">
                  <IoIosMail />
                </button>

              </div>
            </form>
            <div
              className="d-flex align-items-center mt-3"
              style={{ gap: "20px" }}
            >
              <div className="icons_box d-flex align-items-center justify-content-center">
                <a href="https://www.linkedin.com/company/om-sai-group/" rel="noreferrer" target='_blank'><FaLinkedin /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <img src={Component_234_1} className="daak_ghar" alt="error" />
          </div>
        </div>
      </div>

      <div className="contact sm-pt3" data-aos="fade-up">
        <div className="tech_contact sm-none">
          <img src={Group_74103} alt="tech" width="239px" height="239px" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <div
                style={{ color: "#000000", fontSize: "40px" }}
                className="sm-30"
              >
                <b className="sm-25">We would like to here from you !</b>
              </div>
              <div
                style={{ fontSize: "18px", color: "#555554" }}
                className="mt-4 sm-14"
              >
                Just leave your email with us! and we'll get back to you!
              </div>
              <div
                className="mt-5 d-flex align-items-center"
                style={{ gap: "25px" }}
              >
                <img src={Group_74104} alt="error" />
                <img src={Path_115542} alt="error" />
              </div>
            </div>
            <div className="col-lg-1 col-md-2"></div>
            <div className="col-lg-7 col-md-6 col-12">

              <form onSubmit={contactSubmit}>
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ height: "" }}
                >
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6 mt-4">
                    <div className="form-group">
                      <input
                        className="form-control join_form"
                        data-aos="fade-right"
                        type="text"
                        value={contactUs.name}
                        onChange={(e) => setContactUs({ ...contactUs, name: e.target.value })}
                        name="full_name"
                        placeholder="Your full name"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <div className="form-group">
                      <input
                        className="form-control join_form"
                        data-aos="fade-left"
                        type="text"
                        value={contactUs.reason}
                        onChange={(e) => setContactUs({ ...contactUs, reason: e.target.value })}
                        name="from_subject"
                        placeholder="Reason of Contact"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <div className="form-group">
                      <input
                        className="form-control join_form"
                        data-aos="fade-right"
                        name="from_email"
                        value={contactUs.email}
                        onChange={(e) => setContactUs({ ...contactUs, email: e.target.value })}
                        type="email"
                        placeholder="Your email address"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <div className="form-group">
                      <input
                        className="form-control join_form"
                        data-aos="fade-left"
                        name="from_phone"
                        type="text"
                        value={contactUs.phone}
                        onChange={(e) => setContactUs({ ...contactUs, phone: e.target.value })}
                        maxLength='10'
                        placeholder="Your mobile number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-3">
                    <div className="form-group">
                      <input
                        className="form-control own_input"
                        placeholder="Your mobile number"
                        name="reply_to"
                        value='Thank you contacting us'
                        type="hidden"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-12 mt-4">
                    <div className="form-group">
                      <input
                        className="form-control join_form"
                        data-aos='fade-left'
                        type="text"
                        style={{ height: '100px' }}
                        value={contactUs.message}
                        onChange={(e) => setContactUs({ ...contactUs, message: e.target.value })}
                        name="message"
                        placeholder="Your message..."
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ padding: "22px 0px 50px 0px" }}
                >
                  <button className="btn btn-contact" data-aos="flip-right" type="submit">
                    <div className="d-flex justify-content-center aling-items-center" >
                      <div>Send</div>
                      <div className="ml-2">
                        <FaTelegramPlane style={{ fontSize: "17px" }} />
                      </div>
                    </div>
                  </button>
                </div>
              </form>
              {/* <form onSubmit={contactSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input
                        className="form-control own_input"
                        placeholder="Your full name"
                        type="text"
                        name="full_name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input
                        className="form-control own_input"
                        placeholder="How can we help ?"
                        name="from_subject"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-3">
                    <div className="form-group">
                      <input
                        className="form-control own_input"
                        placeholder="Your email address"
                        name="from_email"
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-3">
                    <div className="form-group">
                      <input
                        className="form-control own_input"
                        placeholder="Your mobile number"
                        name="from_phone"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-3">
                    <div className="form-group">
                      <input
                        className="form-control own_input"
                        placeholder="Your mobile number"
                        name="reply_to"
                        value='Thank you contacting us'
                        type="hidden"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 ">
                    <div className="form-group">
                      <textarea
                        rows="3"
                        className="form-control own_input"
                        placeholder="Type your message here for us"
                        type="text"
                        name="message"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button className="d-flex align-items-center justify-content-center send_btn btn" style={{ gap: "8px" }} type="submit" >
                    <div>Send</div>
                    <FiSend style={{ fontSize: "18px" }} />
                  </button>
                </div>
              </form> */}
            </div>
          </div>
        </div>

        <div className="tech_circle">
          <img
            src={Group_54508}
            alt="tech"
            width="381px"
            height="381px"
            className="sm-none"
          />
        </div>
      </div>

      <div className="footer_up">
        <div className="sm-none">
          <Footer />
        </div>
        <div className="lg-none">
          <FooterMob />
        </div>
      </div>
    </>
  );
};

export default Home;
