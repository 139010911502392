import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import Footer from '../Layout/Footer'

import Component_225_1 from "../../Images/Component_225_1.svg";
// Images
import FooterMob from '../Layout/FooterMob'
import { FaTelegramPlane } from "react-icons/fa";
import { useState } from 'react'
import axios from 'axios'

const AddVacancy = () => {
    const [department, setDepartment] = useState('')
    const [degination, setDegination] = useState('')
    const [location, setLocation] = useState('')
    const [vacancy, setVacancy] = useState()
    const [message, setMessage] = useState('')
    const navigate = useNavigate();
    const admin = localStorage.getItem('admin');
    useEffect(() => {
        if (admin === null || admin === undefined) {
            navigate('/login')
        }
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://api-techigent.vercel.app/vacancy/create', {
            Department: department,
            Degination: degination,
            noofvacancy: vacancy,
            location: location,
            jobDes: message,
            Status: '1',
            companyStatus: 1
        })
            .then((response) => {
                console.log(response);
                alert("Added Successfully")
                setDegination('')
                setVacancy('')
                setLocation('')
                setMessage('')
            }, (error) => {
                console.log(error);
            });
    };

    return (
        <>
            <div style={{ marginTop: '100px' }}>
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-around sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/contactDetails')}><b>Requested ContactUs</b></div>
                            <div style={{ paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/candidate')}><b>Candidate</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/dashboard')}><b>Added Vacancy</b></div>
                            <div style={{ color: "#C51213", borderBottom: "2px solid #C51213", paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/product&service/endpoints')}><b>Add New Vacancy</b></div>
                        </div>
                    </div>

                    <div
                        className='ml-4 mr-4'
                        style={{ backgroundColor: "#F2F5F8", position: "relative" }}
                        data-aos="fade-up"
                    >
                        <div className="l_e_c_1 sm-none">
                            <img src={Component_225_1} alt="Error" />
                        </div>
                        <div className="l_e_c_2 sm-none">
                            <img src={Component_225_1} alt="Error" />
                        </div>
                        <div style={{ padding: "80px 0px", position: "relative" }}>
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100%" }}
                            >
                                <form style={{ width: "770px" }} onSubmit={HandleSubmit}>
                                    <div
                                        className="d-flex flex-column align-items-center"
                                        style={{ height: "" }}
                                    >
                                        <div style={{ fontSize: "30px" }}>
                                            <b className="sm-25">Vacancy Details</b>
                                        </div>
                                        <div className="join-1">
                                            Fill All Details
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 col-lg-6 mt-4">
                                            <div className="form-group">
                                                <select name="department" id="depart"
                                                    placeholder='Department'
                                                    className="form-control join_form1"
                                                    data-aos="fade-right"
                                                    value={department}
                                                    onChange={(e) => setDepartment(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option>Marketing & Sales</option>
                                                    <option>Accounting & Finance</option>
                                                    <option>Operations & Distribution</option>
                                                    <option>Technology Solutions</option>
                                                    <option>HR & Admin</option>
                                                    <option>Service & Support</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 mt-4">
                                            <div className="form-group">
                                                <input
                                                    className="form-control join_form"
                                                    data-aos="fade-left"
                                                    type="text"
                                                    value={degination}
                                                    onChange={(e) => setDegination(e.target.value)}
                                                    placeholder="Degination"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 mt-4">
                                            <div className="form-group">
                                                <input
                                                    className="form-control join_form"
                                                    data-aos="fade-right"
                                                    type="number"
                                                    value={vacancy}
                                                    onChange={(e) => setVacancy(e.target.value)}
                                                    placeholder="No of Vacancy"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 mt-4">
                                            <div className="form-group">
                                                <input
                                                    className="form-control join_form"
                                                    data-aos="fade-left"
                                                    type="text"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    placeholder="Location"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12 mt-4">
                                            <div className="form-group">
                                                <textarea
                                                    rows="4"
                                                    className="form-control join_form"
                                                    data-aos="fade-up"
                                                    type="text"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder="Job description"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex justify-content-center"
                                        style={{ padding: "22px 0px 50px 0px" }}
                                    >
                                        <button className="btn btn-contact" data-aos="flip-right" onClick={() => HandleSubmit()}>
                                            <div className="d-flex justify-content-center aling-items-center">
                                                <div>Submit</div>
                                                <div className="ml-2">
                                                    <FaTelegramPlane style={{ fontSize: "17px" }} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddVacancy