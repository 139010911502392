import React from 'react'
import { useNavigate } from 'react-router-dom'

// Images
import Path_115542 from "../../Images/Path_115542.svg";
import Group_74104 from "../../Images/Group_74104.svg";
import { AiFillHeart } from 'react-icons/ai'

const FooterMob = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="footer_mob">
                <div className="container">

                    <div className="d-flex justify-content-betwwen sm-pb2 mt-2" onClick={() => navigate("/")}>

                        <div
                            className="d-flex align-items-center"
                            style={{ width: '184px', height: '50px' }}
                        >
                            <img src={Group_74104} height='50px' alt="error" />
                            <img className='ml-1' src={Path_115542} alt="error" height='50px' />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <div>
                            <div className="twenty"><b>COMPANY</b></div>
                            <ul className="footer_ul">
                                <li onClick={() => navigate('/product&service/endpoints')}>Solutions</li>
                                <li onClick={() => navigate('/about')}>About Us</li>
                                <li onClick={() => navigate('/careers')}>Careers</li>
                                <li onClick={() => navigate('/awards&recogination')}>Awards</li>
                            </ul>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <div className="twenty"><b>OTHERS</b></div>
                        <ul className="footer_ul">
                            <li onClick={() => navigate('/case-study')}>Case Studies</li>
                            <li>Cookies Policy</li>
                            <li>Privacy Policy</li>
                            <li onClick={() => navigate('/contact')}>Contact Us</li>
                        </ul>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                        <div>
                            <div className="twenty"><b>REACH US</b></div>
                            <div className="footer_div" style={{ width: "434px", fontSize: '12px' }}>
                                <div><b>Mumbai Office</b></div>
                                <div>281-283, V-Mall, Near Sai Dham, W.E. Highway,<br /> Kandivali(East), Mumbai-400101.</div>
                                <div className="mt-2 mb-2">+91 22 28700178/ +91 22 28700179/ +91 22 28700180</div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_mob_border sm-pt2">
                        <div className="sm-14 text-center">OM SAI CORPORATION © 2023.<br></br> All rights Reserved.</div>
                    </div>
                    <div className='text-center mt-2 mb-2' style={{ fontSize: '12px', opacity: '0.6' }} >Made with <AiFillHeart /> by JUGGLERS</div>
                </div>
            </div>
        </>
    )
}

export default FooterMob